@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");

.collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* background-color: #fff; */
  border-radius: 10px;
}

.collection > h3 {
  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

@media screen and (max-width: 1366px) {
  .collection {
    width: 100%;
  }

  .collection > h3 {
    margin-left: 20px;
    margin-right: 20px;
  }
}
