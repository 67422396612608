@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.footerPartners {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.1) 80%,
      #f3f3f3
    ),
    url(../images/footerBackPartners.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  max-height: 300px;
  background-color: #f3f3f3;
  margin-top: -20px;
}

.specx {
  font-weight: 400;
  font-family: "Cormorant Garamond", serif;
  color: #d0b49f;
}

.footerPartners > .tophalf {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.footerPartners > .tophalf > .main > .newsletter {
  padding: 40px;
  width: 500px;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  margin-top: -20px;
}

.footerPartners > .tophalf > .main > .newsletter h3 {
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

.footerPartners > .tophalf > .main .newsletter form {
  /* display: flex; */
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: auto;
}

.footerPartners > .tophalf > .main .newsletter form .input {
  border: 1px solid #fff;
  font-family: "Raleway", sans-serif;

  width: 80%;
  padding: 10px;
  background-color: transparent;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: auto;

  /* border-radius: 10px; */
  transition: 0.3s;
}

.footerPartners > .tophalf > .main .newsletter form .input::placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerPartners > .tophalf > .main .newsletter form .input:focus {
  outline: none;
  border-bottom: 2px solid #fff;
}

.footerPartners > .tophalf > .main .newsletter form .button {
  border: 2px solid #fff;
  padding: 9px;
  background-color: #fff;
  color: black;
  text-transform: uppercase;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s;
}

.footerPartners > .tophalf > .main .newsletter form .button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
}

.footerPartners > .tophalf > .main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footerPartners > .tophalf > .main .contentSection > h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #008080;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerPartners > .tophalf > .main .contentSection > h1 span {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #d7be69;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerPartners > .tophalf > .main > .contentSection h4 {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  text-align: center;
  /* margin-top: 5px; */
  /* text-transform: uppercase; */
  margin-top: 85px;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerPartners > .tophalf > .main > .icons {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 400px !important;
  margin-right: -20px;
  margin-left: 100px;
}

.footerPartners > .tophalf > .main > .icons > .iconsContainer {
  padding: 10px;
  display: flex;
  margin-left: 40px;
  justify-content: space-evenly;
  width: 300px !important;
  margin-top: 10px;
}

.footerPartners > .tophalf > .main > .icons > h3 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  margin-top: -13px;
}

.footerPartners > .tophalf > .main > .icons > .iconsContainer a .icon {
  font-size: 40px;
  border: 1px solid;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
  text-decoration: none;
  padding: 10px;
  color: #fff;
  margin-left: -20px !important;
  margin-right: -20px !important;
  transition: 0.4s;
  margin-top: -7px;
}

.footerPartners > .tophalf > .main > .icons > .iconsContainer > a .icon:hover {
  color: #d0b49f;
  border: 2px solid #d0b49f;
  cursor: pointer;
}

.footerPartners > .tophalf > .solutionSection {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.footerPartners > .tophalf > .solutionSection h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
}

.footerPartners > .tophalf > .solutionSection h3 > a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

.footerPartners > .tophalf > .solutionSection h3 > a:hover {
  color: #008080;
  cursor: pointer;
}

#bottomNewCont {
  display: flex;
  width: 82%;
  margin: auto;
  justify-content: space-between;
  margin-top: 35px;
}

#leftSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  margin-left: -10px !important;
  text-align: center;
  text-transform: capitalize;
}

#rightSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
  margin-left: -10px !important;
  margin-top: 3px !important;
  text-transform: capitalize;
}

#rightSolution a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

#rightSolution a:hover {
  color: #008080;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .footerPartners > .tophalf > .main > .icons {
    margin-left: 0px;
    margin-top: 40px;
  }
  .footerPartners > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 400px;
    margin-top: 10px;
  }

  .footerPartners > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  .footerPartners > .tophalf > .main > .newsletter {
    padding: 40px;
    width: 400px;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    margin-top: -20px;
  }

  .footerPartners > .tophalf > .main .newsletter form .input {
    border: 1px solid #fff;
    font-family: "Raleway", sans-serif;

    width: 60%;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin: auto;
    margin-left: 20px;
    /* border-radius: 10px; */
    transition: 0.3s;
  }

  .footerPartners > .tophalf > .main .contentSection > h1 {
    margin-top: -95px;
  }

  #rightSolution {
    margin-top: -4px !important;
  }

  #leftSolution {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 768px) {
  .footerPartners {
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.1) 80%,
        #f3f3f3
      ),
      url(../images/footerBackPartners.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    max-height: 400px;
  }

  .footerPartners > .tophalf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .footerPartners > .tophalf > .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .footerPartners > .tophalf > .main > .icons {
    margin-left: -20px;
  }

  .footerPartners > .tophalf > .main > .icons .iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerPartners > .tophalf > .main > .icons .iconsContainer > a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    transition: 0.4s;
    margin-right: 15px;
    margin-left: 15px;
  }
  .footerPartners > .tophalf > .main .newsletter form {
    justify-content: center;
    align-items: center;

    margin: auto;
    width: 100%;
  }

  .footerPartners > .tophalf > .main .newsletter form .input {
    width: 50%;
    margin-left: 40px;
    align-self: center;
  }

  .footerPartners > .tophalf > h3 > a {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerPartners > .tophalf > .solutionSection {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .footerPartners > .tophalf > .main .contentSection > h1 {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerPartners > .tophalf > .main .contentSection > h1 span {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerPartners > .tophalf > .main > .contentSection h4 {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    /* text-transform: uppercase; */

    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  }

  .footerPartners > .tophalf > .main > .newsletter h3 {
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  }

  .footerPartners > .tophalf > .solutionSection h3 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-size: 10px;
    text-align: center;
  }

  .footerPartners > .tophalf > .solutionSection h3 > a {
    font-family: "Raleway", sans-serif;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerPartners > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 800px;
    margin-top: 10px;
  }

  .footerPartners > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  #bottomNewCont {
    width: 100% !important;
    margin: auto;
    flex-direction: column;
    margin-top: 0px;
  }

  #leftSolution {
    text-align: center;
    font-size: 10px !important;
    margin-left: 0px !important;
    margin-bottom: 5px;
  }

  #rightSolution {
    text-align: center;
    font-size: 10px !important;
  }
}
