@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.slider {
  padding: 10px;
  max-width: 730px !important;
}

.tile {
  display: flex;
  flex-direction: column;
  max-width: 730px;

  overflow: hidden;
  width: 530px;
}

.tile > img {
  width: 710px;
  border-radius: 5px;
}

.tile > h2 {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10px;
  font-size: 15px;
}

@media screen and (max-width: 1366px) {
  .slider {
    max-width: 600px;
  }

  .tile {
    display: flex;
    flex-direction: column;
  }
  .tile > img {
    width: 700px;
    margin: auto;
  }

  .tile > h2 {
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .tile > img {
    width: 280px;
    margin: auto;
  }

  .tile > h2 {
    font-size: 10px;

    text-align: center;
  }
}
