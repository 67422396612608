@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');


.navbarFilter {
  display: flex;
  margin: auto;
  max-width: 60%;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid #008080;
  padding: 10px;
  border-radius: 30px;
  color: #008080;

  transition: 0.5s;
}

.link:hover {
  background-color: #008080;
  color: white;
}

@media screen and (max-width: 1366px) {
  .navbarFilter {
    flex-wrap: wrap;
  }

  .link {
    font-size: 12px;
    padding: 7px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 768px) {
  .navbarFilter {
    flex-wrap: wrap;
    max-width: 80%;
    justify-content: center;
  }

  .link {
    font-size: 12px;
    padding: 7px;
    margin-bottom: 8px;
  }
}


