@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mate+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap");

section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-self: center;
  margin-bottom: 15px;
  background-color: #f3f3f3;
  background: url(../images//storyBack.png);
  background-size: cover;
  overflow: hidden;
}

.story {
  flex: 0.5;
  display: flex;
  flex-direction: column;

  max-width: 900px;
  /* margin-left: 50px; */
  padding: 40px 20px;
}

.slideshow {
  flex: 0.5;
  max-width: 550px;
  background-color: #fff;
  border-radius: 10px;
  /* margin-left: -20px; */
  /* padding: 20px 10px; */
}

.story > h1 {
  /* font-family: "Staatliches", cursive; */

  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  /* font-family: 'Philosopher', sans-serif; */
  font-size: 50px;
  margin-bottom: 40px;
  color: #008080;
}

.story > .content > p {
  /* font-family: "Mate SC", serif; */
  line-height: 25px;
  font-family: "Raleway", sans-serif;
  text-align: justify;
  color: #383c3d;
  margin-top: 15px;
  line-height: 30px;
  font-size: 19px;
}

.special1 {
  color: #d0b49f;
  /* font-family: "Staatliches", cursive; */
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
}

@media screen and (max-width: 1366px) {
  section {
    background: url(../images/storyBack1366.png);
    background-size: cover;
  }

  .slideshow {
    /* margin-top: 120px; */
    width: 440px;
    max-width: 440px;
  }

  .story {
    max-width: 600px;
  }

  .story > .content > p {
    /* font-family: "Mate SC", serif; */
    line-height: 20px !important;
    font-family: "Raleway", sans-serif;
    text-align: justify;
    color: #383c3d;
    margin-top: 15px;

    font-size: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .slideshowMobile {
    display: none;
  }

  .story > .content > p {
    /* font-family: "Mate SC", serif; */
    line-height: 25px !important;
    font-family: "Raleway", sans-serif;
    text-align: justify;
    color: #383c3d;
    margin-top: 15px;

    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  section {
    display: flex;
    flex-direction: column;
  }

  .story {
    margin-left: 0;
  }

  .slideshow {
    width: 335px;
  }
}
