@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap");

#navbar {
  max-height: 70px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

#navbar > .image > .imglink > .white {
  width: 130px;
  margin-top: 5px;
  margin-left: 100px;
}

#navbar > .contents > .links > .spy {
  display: flex;
  list-style: none;
  padding: 25px;
}

#navbar > .contents > .links > .spy > li {
  margin-left: 15px;
  margin-right: 15px;
}

#navbar > .contents > .links > .spy > li > .oneLink {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: 0.3s;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
}

#navbar > .contents > .links > .spy > li > .oneLink:hover {
  cursor: pointer;
  color: #d0b49f;
}

.active {
  visibility: visible;
  transition: all 0.5s;
}
.hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}

.mobileView {
  display: none;
}

.is-current {
  font-weight: 600 !important;
}

@media screen and (max-width: 1366px) {
  #navbar > .contents > .links > .spy > li > .oneLink {
    text-decoration: none;
    color: #fff;
    transition: 0.3s;
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
  }

  #navbar > .image > .imglink > .white {
    width: 110px;
    margin-top: 5px;
    margin-left: 50px;
  }

  #navbar {
    max-height: 60px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #navbar > .contents > .links > .spy {
    display: flex;
    list-style: none;
    padding: 20px;
  }

  .mobileView {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #navbar {
    display: none;
  }

  .mobileView {
    display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #fff;
    z-index: 99;
    padding: 5px;
    height: 65px;
    width: 100%;
  }

  .mobileView > .icon {
    font-size: 35px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: -30px;
  }

  .mobileView > img {
    width: 120px;
    margin: auto;
  }
}

.dropdown-li {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 5px;
}

.dropdown-li:hover {
  background-color: #008080;
}

.dropdown-ul {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  margin-top: 10px;
  width: 250px;
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style: none;
  border-radius: 5px;
  opacity: 0;
  transform: translate(-10px);
  transition: all 0.4s ease;
}

.dropdown-a {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.droptoggler:focus + .dropdown-ul {
  opacity: 1;
  transform: translate(0px);
}

@media screen and (max-width: 1366px) {
  .dropdown-a {
    font-size: 10px;
    padding: 5px;
  }

  .dropdown-ul {
    height: 100px;
    width: 150px;
  }
}
