@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.superDesContainerMobile {
  display: none;
}

@media screen and (max-width: 1366px) {
  .superDesContainerMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superDesContainerMobile {
    display: flex;
    max-width: 350px !important;
    width: 350px !important;
    height: 50vh !important;
  }

  .superDesContainerMobile > .mainMobile_Destinations {
    display: flex;
    max-width: 350px !important;
    width: 350px !important;
  }
}
