@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.superContainer_Destinations {
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
  border-radius: 10px;
  height: max-content;
  max-height: 90vh !important;
  width: 91vw;
  margin-bottom: 20px;
}

.superContainer_Destinations > .navSlider {
  max-width: 1900px;
  width: 1900px;
  padding: 20px;
}

.superContainer_Destinations > .navSliderMobile {
  display: none;
}

.superContainer_Destinations > .navSlider .box {
  display: flex;

  width: 50px;
  margin-right: 5px;
}

.superContainer_Destinations > .navSlider .navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  width: 110px;
  max-width: 355px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border-left: 0px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.superContainer_Destinations > .navSlider .navxx:hover {
  cursor: pointer;
  border-left: 2px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.superContainer_Destinations > .navSlider .navxx > .dayNo {
  font-weight: 600;
  font-family: "Raleway", sans-serif !important;
  font-weight: 400;
  color: #008080;
  text-align: center;
  line-height: 1rem;
  font-size: 12px;
  margin-bottom: 3px;
}

.superContainer_Destinations > .navSlider .navxx > .dayTour {
  font-size: 12px;
  text-align: center;

  color: gray;
}

.superContainer_Destinations > .main {
  flex: 0.6;
  max-width: 1000px;
}

.superContainer_Destinations > .mainMobile {
  display: none;
}

.superContainer_Destinations > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Destinations {
    height: max-content !important;
    max-height: 135vh !important;
  }
  .superContainer_Destinations > .mainMobile {
    display: none;
  }
  .superContainer_Destinations > .navSlider {
    width: 1400px;
  }

  .superContainer_Destinations > .navSlider .navxx {
    width: 85px;
  }

  .superContainer_Destinations > .navSlider .navxx > .dayNo {
    font-size: 10px;
    line-height: 0.9rem;
  }

  .superContainer_Destinations > .main {
    max-width: 860px;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Destinations {
    display: none;
  }

  .superContainer_Destinations > .navSlider {
    display: none;
  }
  .superContainer_Destinations > .mainMobile {
    display: none;
  }

  .superContainer_Destinations > .main {
    display: none;
  }
}
