.superContainer_Wildlife {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 90vh !important;
}

.superContainer_Wildlife > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Wildlife > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Wildlife > .main {
  flex: 0.6;
  max-width: 800px;
}

.superContainer_Wildlife > .mainMobile {
  display: none;
}

.superContainer_Wildlife > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Wildlife {
    height: 130vh !important;
    max-height: 130vh !important;
  }
  .superContainer_Wildlife > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Wildlife {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Wildlife > .navSlider {
    display: none;
  }
  .superContainer_Wildlife > .mainMobile {
    display: flex;
  }

  .superContainer_Wildlife > .main {
    display: none;
  }
}
