@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.MainContainer {
  /* padding: 0px; */
}

.wrapper {
  display: flex;

  justify-content: space-evenly;
  /* padding: px; */
  /* padding-left: 30px; */
  /* padding-right: 30px; */
}

.wrapper > .left {
  overflow: hidden;
  width: 400px;
  height: 680px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  background-color: #008080;
  background: url(../images/maldivesBack.png);
  padding-left: -10px;
  /* margin-left: -30px; */
}

.wrapper > .left > .title {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 15px;
  margin-left: 10px;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  padding: 10px;
}

.wrapper > .left > .content {
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 12px !important;
}

.wrapper > .left > .image {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  margin-bottom: 10px;
  display: none;
}

.wrapper > .right {
  width: 600px;
  padding: 20px;
}

.wrapper > .left > h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 19px;
}

.wrapper > .right > form > .input {
  border: 1px solid #f4f4f4;
  color: black;
  width: 94%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
  padding: 10px;
  border-radius: 5px;
  background-color: #f3f3f3;
  letter-spacing: 1px;
  transition: 0.3s;
  margin-bottom: 10px;
}

.wrapper > .right > form > .input::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
}

.wrapper > .right > form > .input:focus {
  background-color: white;
  outline: none;
  border-color: #008080;
}

.wrapper > .right > form > .inputField {
  display: none;
}
.buttons {
  margin: auto;
  /* width: 100%; */
  /* justify-content: space-evenly; */
  display: flex;
  /* position: absolute;
  bottom: 0; */
  font-family: "Raleway", sans-serif;
  margin-bottom: 10px;
}

.buttons > button {
  display: none;
}

.theButton {
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  background-color: #008080;
  color: white;
  border-radius: 15px;
  margin-right: 20px;
  transition: 0.3s;
}

.theButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .wrapper > .left {
    display: none;
  }
  .wrapper > .right {
    width: auto;
    /* padding: 40px; */
    padding-left: 20px !important;
  }

  .wrapper > .right > form > .input {
    border: 1px solid #f4f4f4;
    color: black;
    width: 90%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
    padding: 10px;
    border-radius: 5px;
    background-color: #f3f3f3;
    transition: 0.3s;
    margin-bottom: 10px;
  }
  .wrapper > .right > form {
    padding: 20px;
    max-width: 300px;
  }

  .buttons {
    position: relative;
  }
}
