@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap");

.containerCover {
  width: 100%;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  /* margin-left: -55px; */
}

.slideCover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  max-width: 550px;
  border-radius: 10px;
  background-color: #fff;
  outline: none;
}

.slideCover img {
  width: 550px;
  max-width: 550px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.slideCover > h3 {
  font-family: "Raleway", sans-serif;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
}

.slideCover > p {
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  text-align: justify;
  text-align-last: center;
  padding: 20px 50px;
  line-height: 1.1rem;
  color: rgb(66, 66, 66);
}

@media screen and (max-width: 1366px) {
  .slideCover > img {
    width: 440px;
    max-width: 440px;
  }

  .slideCover > p {
    padding: 20px 20px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .slideCover > img {
    width: 335px;
  }

  .slideCover > h3 {
    font-size: 16px;
  }

  .slideCover > p {
    font-size: 12px;
  }
}
