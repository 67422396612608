@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mate+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

.container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f3f3f3;
  margin-top: -15px;
}

.container > h1 {
  margin: auto;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
  color: #008080;
}

.special {
  color: #d0b49f;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
}

.container > .slidersContainer {
  padding: 10px;
}

.container > .slidersContainer > .top {
  padding: 10px;
  margin-bottom: 10px;
}

.container > .slidersContainer > .bottom {
  padding: 10px;
  margin-bottom: 10px;
}

.container > .slidersContainer > .top > h2 {
  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  text-align: center;
  font-weight: 400;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
  /* background-color: ; */
  /* border-radius: 5px; */
  margin-bottom: 15px;
  padding: 10px;
  text-transform: uppercase;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.top__slider {
  margin: 5px;
}

.bottom__slider {
  margin: 5px;
}

.container > .slidersContainer > .bottom > h2 {
  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  text-align: center;
  font-weight: 400;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
  /* background-color: ; */
  /* border-radius: 5px; */
  margin-bottom: 15px;
  padding: 10px;
  text-transform: uppercase;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

@media screen and (max-width: 768px) {
  .desktopSlider {
    display: none;
  }

  .container > .slidersContainer > .bottom > h2 {
    font-family: "Didact Gothic", sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin: auto;
    text-align: center;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    /* background-color: #191c1d; */
    /* border-radius: 5px; */
    margin-bottom: 15px;
    padding: 10px;
    text-transform: uppercase;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }

  .container > .slidersContainer > .top > h2 {
    font-family: "Didact Gothic", sans-serif;
    font-size: 15px;
    margin: auto;
    font-weight: 400;
    text-align: center;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    /* background-color: #191c1d; */
    /* border-radius: 5px; */
    margin-bottom: 15px;
    padding: 10px;
    text-transform: uppercase;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }
}

@media screen and (min-width: 961px) {
  .mobileSlider {
    display: none;
  }
}
