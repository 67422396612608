@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.tour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  transition: 0.4s;
  border-top: 3px solid #3498db;
  overflow: hidden;
}

.tour:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.tour > .line {
  display: flex;
  margin-bottom: 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 3px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.tour > .line h3 {
  text-align: center;
  text-transform: uppercase;
  color: #008080;
  white-space: normal;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 13px;
  margin-right: 10px;
}

.tour > .line h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
}

.tour > p {
  font-family: "Raleway", sans-serif;
  padding: 7px;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  font-size: 15px;
}

.tour > .tourSlides {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1366px) {
  .tour {
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  .tour {
    max-width: 300px;
  }

  .tour > .line {
    display: flex;
    flex-direction: column;
  }

  .tour > .line h3 {
    text-align: center;
    text-transform: uppercase;
    color: #008080;
    white-space: normal;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    margin-right: 10px;
  }

  .tour > .line h2 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
  }

  .tour > p {
    font-size: 12px;
  }
}
