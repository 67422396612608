.navbar {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    height: 75px;
    background-color: transparent;
    z-index: 999 !important;
    /* box-shadow: 0 5px 10px rgba(0,0,0,0.2); */
}

.navbar > .image {
    padding: 5px;
}

.navbar > .image > img {
    display: none;
    width: 160px;
    margin-left: 20px;
    z-index: 40;
    -webkit-filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
  filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
}

.navbar > .image > .white {
    display: flex;
    width: 160px;
    margin-left: 20px;
    z-index: 40;
    -webkit-filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
  filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
}

.navbar > .contents {
    display: flex;
}

.navbar > .contents > .links {
    margin-right: -40px;
}

.navbar > .contents > .links > .spy {
    display: flex;
    justify-content: space-between;
    padding: 29px;
    
}

.navbar > .contents > .links > .spy >  li {
    list-style: none;
    margin-right: 60px;
   
    
}

.is-current {
    color: #D0B49f !important;
    font-weight: 600 !important;
    /* border-bottom: 3px solid #D0B49F; */
}

.navbar > .contents > .links > .spy > li > .oneLink {
    text-decoration: none;
    color: white;
    
    text-transform: uppercase;
    text-shadow: 0 0 10px rgba(0,0,0,0.8);
    /* font-weight: 400; */
    transition: 0.3s;
}


.navbar > .contents > .links > .spy > li > .oneLink:hover{
    cursor: pointer;
    color: #D0B49F;
    /* border-bottom: 3px solid #D0B49F; */
    transition: border-bottom 0.2s ease-in-out;
}



.navbar > .contents > .icons {
    display: flex;
    padding: 25px;
}
.navbar > .contents > .icons > a {
    text-decoration: none;
    color: #008080;
}

.navbar > .contents > .icons > a > .icon {
    margin-left: 10px;
    transition: 0.3s;
}

.navbar > .contents > .icons > a > .icon:hover {
    color: #D0B49F;
    cursor: pointer;
}

.mobileView {
    display: none;
}


/*********************************/

.navbar--scrolled {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    height: 75px;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

.navbar--scrolled > .image {
    padding: 5px;
}

.navbar--scrolled > .image > img {
    width: 130px;
    margin-left: 20px;
    z-index: 40;
}

.navbar--scrolled > .image > .white {
    display: none;
}

.navbar--scrolled > .contents {
    display: flex;
}

.navbar--scrolled > .contents > .links {
    margin-right: -40px;
}

.navbar--scrolled > .contents > .links > .spy {
    display: flex;
    justify-content: space-between;
    padding: 29px;
}

.navbar--scrolled > .contents > .links > .spy >  li {
    list-style: none;
    margin-right: 60px;
    
}

.is-current {
    color: #D0B49F !important;
    /* border-bottom: 3px solid #D0B49F; */
    font-weight: 600;
}

.navbar--scrolled > .contents > .links > .spy > li > .oneLink {
    text-decoration: none;
    color: #008080;
    text-transform: uppercase;
    /* text-shadow: 0 0 10px rgba(0,0,0,0.8); */
    /* font-weight: 400; */
    transition: 0.3s;
}

.navbar--scrolled > .contents > .links > .spy > li > .oneLink:hover{
    cursor: pointer;
    color: #D0B49F;
    /* border-bottom: 3px solid #D0B49F; */
    transition: border-bottom 0.2s ease-in-out;
}



.navbar--scrolled > .contents > .icons {
    display: flex;
    padding: 25px;
}
.navbar--scrolled > .contents > .icons > a {
    text-decoration: none;
    color: #008080;
}

.navbar--scrolled > .contents > .icons > a > .icon {
    margin-left: 10px;
    transition: 0.3s;
}

.navbar--scrolled > .contents > .icons > a > .icon:hover {
    color: #D0B49F;
    cursor: pointer;
}

.mobileView {
    display: none;
}

@media screen and (max-width: 1366px) {
    .navbar {
        height: 60px;
    }

    .navbar > .image > .white {
        display: flex;
        width: 120px;
        margin-left: 20px;
        z-index: 40;
        -webkit-filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
      filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
    }

    .navbar > .contents > .links > .spy >  li {
        margin-right: 40px;
    }

    
    .navbar > .contents > .links > .spy > li > .oneLink {
        font-size: 12px;
    }
}


@media screen and (max-width: 768px) {
    .navbar {
        display: none;
    }

    .navbar--scrolled {
        display: none;
    }

    .mobileView {
        display: flex;
        justify-content: space-between;
        position: fixed;
        background-color: #fff;
        z-index: 99;
        padding: 5px;
        height: 65px;
        width: 100%;
    }

    .mobileView > .icon {
        font-size: 35px;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: -30px;
    }

    .mobileView > img {
        width: 120px;
        margin: auto;
        
    }
}