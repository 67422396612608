.sriLanka_Package {
    background-color: #fff;
}

.sriLanka_Package > .nav {
    background-color: transparent;
    position: fixed;
    z-index: 999;
}

.sriLanka_Package > .headtest {
    display: flex;
    padding: 40px;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(../images/sample.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    background-position: center;

}

.sriLanka_Package > .headtest > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3;
    margin-right: 40px;
    z-index: 4;
}

.sriLanka_Package > .headtest > .leftSide > img {
    
    width: 500px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0,0,0,0.7);
    margin-left: 150px;
}


.sriLanka_Package > .headtest  > .rightSide {
    display: flex;
    flex: 0.5;
    flex-direction: column;
    /* background-color: royalblue; */
}

.sriLanka_Package > .headtest  > .rightSide > h3 {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    background-color: gray;
    color: white;
    width: fit-content;
    padding: 5px 10px;
    /* margin-top: 200px; */
}

.sriLanka_Package > .headtest  > .rightSide > h1 {
    font-family: "century-gothic", sans-serif;
    text-transform: uppercase;
    font-size: 60px;
    font-weight: 400;
    margin-top: 10px;
    color:  rgba(255,255,255,0.6);
}

.sriLanka_Package > .headtest  > .rightSide > .button {
    max-width: 200px;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #008080;
    color: white;
    text-transform: uppercase;
    font-family: "century-gothic", sans-serif;
    font-size: 17px;
   
    border-bottom-right-radius: 20px;
    margin-top: 20px;
    transition: 0.4s;
}

.sriLanka_Package > .headtest  > .rightSide > .button:hover {
    background-color: white;
    color: #008080;
    border: 1px solid #008080;
    cursor: pointer;
}

.tourHeading {
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    font-size: 45px;
    color: #008080;
    background-color: #f3f3f3;
    margin-top: 10px;
    padding-top: 10px;
}

.tourHeading > .special {
    color: #d0b49f;
}


.sriLanka_Package > .tourDetails {
    background-color: #f3f3f3;
    padding: 10px;
    display: flex;
    
    justify-content: center;
}

.sriLanka_Package > .hotels {
    background-color: #f3f3f3;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
    color: #008080;
    border: 1px solid #008080;
    padding: 10px 20px;
    font-size: 15px;
    transition: 0.3s;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;

}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
    cursor: pointer;
    background-color: #008080;
    color: #fff;
}

.sriLanka_Package > .hotels > h1 {
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: 400;
    font-size: 50px;
    color: #008080;
    margin-top: 10px;
    margin-bottom: 20px;
}



.sriLanka_Package > .notes {
    padding: 10px;
   
}

.sriLanka_Package > .notes > h2 {
    font-family: "century-gothic", sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    color: #008080;
    margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    
}

.sriLanka_Package > .notes > p {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.sriLanka_Package > .form {
    padding: 15px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    
}



.sriLanka_Package > .form > form {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    width: 50%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 4px rgba(0,0,0,0.4);
}

.sriLanka_Package > .form > form:hover {
    box-shadow: 0 0 2px rgba(0,0,0,0.4);
}

.sriLanka_Package > .form > form > h2 {
    text-align: center;
    font-weight: 400;
    font-family: "century-gothic", sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
    padding: 10px;
    border: none;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus{
    outline: none;
    box-shadow: 0 0 3px #008080;
    background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
    display: flex;
    
}

.sriLanka_Package > .form > form > .seperate2 {
    display: flex;
    margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
    font-family: "century-gothic", sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 1px;
}



.sriLanka_Package > .form > form > .seperate > .field {
    padding: 10px;
    border: none;
    flex: 0.35;
    /* margin-left: 5px; */
    margin-right: 5px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus{
    outline: none;
    box-shadow: 0 0 3px #008080;
    background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    
}



.sriLanka_Package > .form > form > .seperate1 > .field {
    padding: 10px;
    border: none;
    flex: 0.5;
    /* margin-left: 5px; */
    margin-right: 5px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus{
    outline: none;
    box-shadow: 0 0 3px #008080;
    background-color: #fff;
}


.sriLanka_Package > .form > form > .button {
    padding: 10px;
    border: none;
    background-color: #008080;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    color: #fff;
    text-transform: uppercase;
    font-family: "century-gothic", sans-serif;
    border-radius: 5px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover{
    cursor: pointer;
    box-shadow: none;
    background-color: rgba(0,0,0,0.7);

}


@media screen and (width: 1366px) {

    .sriLanka_Package > .headtest {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background-image:linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(../images/sample.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        background-position: center;
    
    }
    
    .sriLanka_Package > .headtest > .leftSide {
        /* background-color: royalblue; */
        display: flex;
        justify-content: center;
        flex: 0.3;
        margin-right: 20px;
        margin-left: 50px;
    }
    
    .sriLanka_Package > .headtest > .leftSide > img {
        
        width: 300px;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,0.7);
        
    }
    
    
    .sriLanka_Package > .headtest  > .rightSide {
        display: flex;
        flex: 0.6;
        flex-direction: column;
        /* background-color: royalblue; */
    }

    
    .sriLanka_Package > .headtest  > .rightSide > h1 {
        font-size: 50px;
    }

    .sriLanka_Package > .headtest  > .rightSide > h3 {
        
        font-size: 12px;
    }

    .sriLanka_Package > .headtest  > .rightSide > .button {
        padding: 10px 20px;
        font-size: 15px;
    }

    .sriLanka_Package > .hotels > .level > .left > h2 {
        font-size: 30px;
    }

    .sriLanka_Package > .hotels > .level > .right > .unit > img {
        width: 240px;
    }
}

.sriLanka_Package > .hotels > .special {
    color: #d0b49f;
    font-family: "century-gothic", sans-serif;
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    .sriLanka_Package > .headtest {
        display: flex;
        flex-direction: column;
        
        background-color: #f3f3f3;
        background-image:linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(../images/travel.jpg);
    }
    .sriLanka_Package > .headtest > .leftSide {
        max-width: 300px;
        
        
    }

    .sriLanka_Package > .headtest > .leftSide > img {
        width: 280px;
        margin-bottom: 10px;
        margin-left: 30px;
        
    }

    .sriLanka_Package > .headtest > .rightSide {
        width: 100%;
    }

    .sriLanka_Package > .headtest > .rightSide > h3 {
        font-size: 10px;
        margin-top: 20px;
        text-align: center;
        display: none;
    }

    .sriLanka_Package > .headtest > .rightSide > h1 {
        font-size: 25px;
        margin-bottom: 10px;
        text-align: center;
        
    }

    .sriLanka_Package > .headtest > .rightSide > .button {
        margin: auto;
        border-radius: 10px;
        padding: 10px 70px;
        font-size: 12px;
    }

    .sriLanka_Package > .tourDetails {
        display: flex;
        flex-direction: column;
    }

    .sriLanka_Package > .hotels > h1 {
        font-size: 28px;
    }

    .sriLanka_Package > .hotels > .level {
        display: flex;
        flex-direction: column;
        border-top: 3px solid #008080;
        border-left: none;
    }

    .sriLanka_Package > .hotels > .level:hover {
        border-left: none;
        border-top: 7px solid #F1C40F;
    }

    .sriLanka_Package > .hotels > .level > .left {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .sriLanka_Package > .hotels > .level > .left > h2 {
        font-size: 22px;
        margin-top: 10px;
    }
    .sriLanka_Package > .hotels > .level > .left > h3 {
        font-size: 12px;
    }

    .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
        font-size: 12px;
    }

    .sriLanka_Package > .hotels > .level > .right > .unit > img {
        width: 220px;
        max-width: 220px;
    }

    .sriLanka_Package > .form > form {
        width: 100%;
        max-width: 100%;
    }

    .sriLanka_Package > .form > form > .seperate2 {
        display: flex;
        
    }

    .sriLanka_Package > .form > form > .seperate {
        display: flex;
        flex-direction: column;
    }

    .sriLanka_Package > .form > form > .seperate1 {
        display: flex;
        flex-direction: column;
        
    }
    
    .sriLanka_Package > .form > form > .seperate1 > .field {
        display: flex;
        
    }
}
