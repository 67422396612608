@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  background-image: none;
}

.team::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.team > .heading > h1 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
  color: #008080;
}

.team > .heading > h1 > span {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
  color: #d0b49f;
}
.containerModule {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.containerModuleSlider {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  max-height: 520px;
}

.card {
  position: relative;
  max-width: 350px;
  height: 450px;
  max-height: 450px;
  padding-top: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* background: rgba(255, 255, 255, 0.05); */
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.card > .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  transition: 0.5s;
  margin-bottom: 10px;
}

.card:hover > .content {
  opacity: 1;
}

.card > .content > .imgBx {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
  border: 5px solid rgba(0, 0, 0, 0.25);
}

.card > .content > .imgBx > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.card > .content > .contentBx > h3 {
  color: black;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1rem;
}

.card > .content > .contentBx > p {
  color: rgba(0, 0, 0, 0.8);
  padding: 10px 30px;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
  text-align-last: center;
  margin: 20px 0 15px;
  line-height: 1.1rem;
}

.card > .content > .contentBx > h3 > span {
  font-size: 11px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  text-transform: initial;
}

.card > .sci {
  position: absolute;
  bottom: 50px;
  display: flex;
}

.card > .sci > li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.3s * var(mystyle));
}

.card:hover > .sci > li {
  transform: translateY(35px);
  opacity: 1;
}

.card > .sci > li > a {
  color: black;
  font-size: 24px;
  transition: 0.3s;
}

.card > .sci > li > a:hover {
  color: #008080;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .containerModuleSlider {
    width: 100%;
  }

  .card {
    position: relative;
    max-width: 300px;
    height: 400px;
    max-height: 400px;
    padding-top: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    /* background: rgba(255, 255, 255, 0.05); */
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
  }

  .card > .content > .imgBx > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    object-fit: cover;
  }

  .card > .content > .imgBx {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 10px;
    overflow: hidden;
    border: 5px solid rgba(0, 0, 0, 0.25);
  }

  .card > .content > .contentBx > h3 {
    color: black;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    margin: 20px 0 15px;
    line-height: 1.1rem;
  }

  .card > .content > .contentBx > p {
    color: rgba(0, 0, 0, 0.8);
    padding: 10px 30px;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 10px;
    text-align: justify;
    text-align-last: center;
    margin: 20px 0 15px;
    line-height: 1.1rem;
  }

  .card > .content > .contentBx > h3 > span {
    font-size: 11px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    text-transform: initial;
  }
}

@media screen and (max-width: 768px) {
  .containerModule > .card {
    position: relative;
    width: 300px;
    height: 570px;
    padding-top: 20px;

    /* background: rgba(255, 255, 255, 0.05); */
    background-color: #fff;
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
  }

  .team > .heading > h1 {
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    font-size: 40px;
    color: #008080;
  }

  .team > .heading > h1 > span {
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    font-size: 40px;
    color: #d0b49f;
  }
}
