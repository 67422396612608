@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

.collectionNav {
  display: flex;
  max-width: 600px;
  margin-bottom: 20px;
  padding: 10px;
}

.collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.collectionNav > .linkx > span {
  background-color: #008080;
  color: #fff;
  font-size: 14px;
  padding: 4px;
  margin-top: 5px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
  width: 70px;
}

.collectionNav > .linkx > span > .icon {
  font-size: 13px;
}

.collectionNav > .linkx:hover > span {
  background-color: #fff;
  color: #008080;
}

@media screen and (max-width: 768px) {
  .collectionNav {
    display: flex;
    overflow: scroll;
    max-width: none;
    flex-wrap: wrap;
  }

  .collectionNav > .linkx {
    margin-right: 0;
    margin-bottom: 10px;
    max-width: 100px;
    font-size: 12px !important;
  }
}
