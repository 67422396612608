
.basicSlider {
  /* margin-top: -70px; */
}

@media screen and (min-width: 1000px) {
    .basicSliderMobile {
      display: none;
    }
}
@media screen and (max-width: 768px) {
  .basicSlider {
    display: none;
  }
}
