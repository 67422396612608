@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap");

.maldivesSection {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
}

.maldivesSection > h1 {
  color: #008080;
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
}

.maldivesSection > h1 > span {
  color: #d0b49f;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
}

.maldivesSection > .contentContainer {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}
