.sliderContainerAwesome {
  background-size: cover;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  background-color: transparent !important;
}

@media screen and (max-width: 1366px) {
  .sliderContainerAwesome {
    max-width: 780px !important;
  }

  .slideDiv {
    max-width: 740px !important;
  }

  .slideDiv > img {
    max-width: 650px !important;
    margin-left: -10px;
  }
}

@media screen and (max-width: 768px) {
  .sliderContainerAwesome {
    max-width: 280px !important;
  }

  .slideDiv {
    max-width: 280px !important;
  }

  .slideDiv > img {
    max-width: 280px !important;
  }
}
