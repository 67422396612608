.active {
  background-color: #f4f4f4;
  height: 80px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.active::before {
  transition: 0.3ms;
}

.disable {
  display: none;
}

.stable {
  color: white;
}

.stable:hover {
  color: #008080;
}

.colorchange {
  color: #008080;
}

.colorchange:hover {
  color: #d0b49f;
}

.colorchange > .pressed {
  text-decoration: underline;
}

.stable > .pressed {
  text-decoration: underline;
}
