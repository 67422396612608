@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mate+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

/*
.slickModule {
  max-width: 555px;
  width: 555px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: white;
 

  padding: 15px;
  transition: 0.3s;
  z-index: 10 !important;
 
}


.slickModule:hover {
  transform: translateY(5px);
}

.slickModule > .imageContainer {
  width: 560px;
  max-width: 560px !important;
}

.slickModule > .imageContainer > img {
  object-fit: contain;
  width: 500px;
  position: relative;
  border-radius: 5px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

@media screen and (max-width: 1366px) {
  .slickModule > .imageContainer > img {
    width: 380px;
  }

  .slickModule {
    width: 410px;
    max-width: 410px !important;
  }
}

.slickModule > .imageContainer > .overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.slickModule > .imageContainer:hover img {
  opacity: 0.7;
}

.slickModule > .imageContainer:hover .overlay {
  opacity: 1 !important;
}

.slickModule > .imageContainer > .overlay > .slickButton {
  padding: 15px 20px;
  background-color: royalblue;
  color: white;
  font-size: medium;
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  transition: 0.5s;
  text-decoration: none;
}

.slickModule > .imageContainer > .overlay > .slickButton:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .slickModule > .imageContainer {
    width: auto;
    object-fit: contain;
    overflow: hidden;
  }

  .slickModule > .imageContainer > .overlay > .slickButton {
    padding: 10px;
    background-color: royalblue;
    color: white;
    font-size: small;
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    text-decoration: none;
  }

  .slickModule > .imageContainer > .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .slickModule {
    width: auto;
  }

  .slickModule > .imageContainer > img {
    width: 300px;
    object-fit: contain;
    overflow: hidden;
  }
}

.slickModule > h2 {
  margin: auto;
  text-align: center;
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  
  padding-top: 10px;
  font-size: large;
}

*/

.total {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slickModule {
  max-width: 560px;
  width: 560px;
  padding-bottom: 10px;
  background-color: white;
  margin: auto;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.slickModule > .imageContainer {
  max-width: 560px;
}

.slickModule > .imageContainer > img {
  object-fit: cover;
  width: 560px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .slickModule {
    max-width: 250px;
    width: 250px;
    padding-bottom: 10px !important;
    background-color: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }

  .slickModule > .imageContainer {
    max-width: 250px;
  }
  .slickModule > .imageContainer > img {
    object-fit: cover;
    /* width: 200px; */
    max-width: 280px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: auto;
  }
}

.slickModule > h2 {
  text-align: center;
  margin-top: 5px;
  font-family: "Raleway", sans-serif;
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.slickModule > .overlay {
  display: flex;
  justify-content: center;
}

.slickModule > .overlay > .slickButton {
  text-decoration: none;
  background-color: #008080;
  padding: 10px 30px;
  margin: auto;
  transition: 0.3s;
  color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.slickModule > .overlay > .slickButton:hover {
  cursor: pointer;
  background-color: #d0b49f;
}

@media screen and (max-width: 1366px) {
  .slickModule > .imageContainer > img {
    object-fit: cover;
    width: 390px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: auto;
  }

  .slickModule {
    max-width: 390px;
    width: 390px;
    padding-bottom: 10px;
    background-color: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
}

@media screen and (max-width: 768px) {
  .slickModule > h2 {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-weight: 400;
  }
}
