.contDest {
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.8) 80%,
      #f3f3f3
    ),
    url(../images/newdest/destBackNew.png);

  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1366px) {
  .contDest {
    height: 110vh;
  }
}

@media screen and (max-width: 768px) {
  .contDest {
    height: 55vh;
  }
}
