@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.partnerWithUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}

.partnerWithUs > .navBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.partnerWithUs > .indexofz {
  z-index: 4;
}

.partnerWithUs > .headerContent {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url(../images//partner.jpg);

  background-size: cover;
  width: 100%;
  height: 70vh;
  background-repeat: no-repeat;
  background-blend-mode: darken;
}

.partnerWithUs > .headerContent > h1 {
  color: #008080;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-align: center;
  margin-top: 250px;
  font-size: 60px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.partnerWithUs > .headerContent > h1 > span {
  color: #d0b49f;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-align: center;
  font-size: 60px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.partnerWithUs > .formContainer {
  background-color: #fff;
  margin-top: -280px;
  margin-bottom: 50px;
  width: 49%;
  display: flex;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  padding: 15px 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.partnerWithUs > .formContainer > form {
  display: flex;
  flex-direction: column;
}

.partnerWithUs > .formContainer > form > .inputField {
  padding: 17px 10px;
  width: 900px;
  max-width: 900px;
  min-width: 900px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
  font-weight: 400;
  margin-bottom: 10px;
  z-index: 0;
}
.partnerWithUs > .formContainer > form > .inputField::placeholder {
  font-family: "Raleway", sans-serif;

  font-weight: 400;
}

.partnerWithUs > .formContainer > form > .inputField:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 3pt #008080;
}

.partnerWithUs > .formContainer > form > .seperate {
  display: flex;
  z-index: 1 !important;
  /* justify-content: space-between; */
}

.inputField1 {
  padding: 17px 10px;
  width: 100%;
  margin-right: 12px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Century-Gothic", sans-serif;
  transition: 0.3s;
  margin-bottom: 10px;
  z-index: 0;
}
.inputField2 {
  padding: 17px 10px;
  width: 100%;

  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Century-Gothic", sans-serif;
  transition: 0.3s;
  margin-bottom: 10px;
}

.inputField1:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 3pt #008080;
}

.inputField2:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 3pt #008080;
}

.label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 8px;
}

.checkarea {
  display: flex;
  padding-top: 4px;
  margin-bottom: 10px;
}

.checker {
  margin-top: 3px;
}

.partnerSubmit {
  padding: 15px;
  width: 200px;
  margin: auto;
  border: none;
  background-color: #008080;
  color: #fff;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 10px;
  font-size: 18px;
  text-transform: uppercase;
  transition: 0.4s;
}

.partnerSubmit:hover {
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.partnerWithUs > .footer {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  /* background-color: #fff; */
  /* width: 100%; */
}

.partnerWithUs > .bottomContent {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  /* width: 40vw; */
  margin-bottom: 20px;
}
.partnerWithUs > .bottomContent > .block {
  background-color: #fff;
  padding: 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 400px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.partnerWithUs > .bottomContent > .block > img {
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  width: 200px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

.partnerWithUs > .bottomContent > .block > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
}

.partnerWithUs > .bottomContent > .block > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 10px;
}

.botTitle {
  font-family: "century-gothic", sans-serif;
  margin-bottom: 10px;
  font-weight: 400;
  color: #008080;
  text-align: center;
  font-size: 35px;
}

.specialPart {
  color: #d0b49f;
}

@media screen and (max-width: 1366px) {
  .partnerWithUs > .headerContent > h1 {
    color: #008080;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;

    font-weight: 400;
    text-align: center;
    margin-top: 200px;
    font-size: 50px;
  }
  .partnerWithUs > .headerContent > h1 > span {
    color: #d0b49f;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;

    font-weight: 400;
    text-align: center;
    margin-top: 200px;
    font-size: 50px;
  }

  .partnerWithUs > .formContainer {
    background-color: #fff;
    margin-top: -150px;
    margin-bottom: 50px;
    width: 70%;
    display: flex;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 15px 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .partnerWithUs > .headerContent {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
      url(../images//partner.jpg);

    background-size: contain;
    width: 700px;
    height: 35vh;
    background-repeat: no-repeat;
    background-blend-mode: darken;
  }

  .partnerWithUs > .headerContent > h1 {
    color: #008080;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
  }
  .partnerWithUs > .headerContent > h1 > span {
    color: #d0b49f;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
  }

  .partnerWithUs > .formContainer {
    background-color: #fff;
    margin-top: -50px;
    margin-bottom: 50px;
    width: 95%;
    display: flex;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 15px 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
  }

  .partnerWithUs > .formContainer > form {
    max-width: 120%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .partnerWithUs > .formContainer > form > .inputField {
    padding: 17px 10px;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: "Century-Gothic", sans-serif;
    transition: 0.3s;
    margin-bottom: 10px;
  }

  .partnerWithUs > .formContainer > form > .seperate {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
  }

  .botTitle {
    font-family: "century-gothic", sans-serif;
    margin-bottom: 10px;
    font-weight: 400;
    color: #008080;
    text-align: center;
    font-size: 25px;
  }

  .partnerWithUs > .bottomContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* width: 40vw; */
    margin-bottom: 20px;
  }
  .partnerWithUs > .bottomContent > .block {
    background-color: #fff;
    padding: 70px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
  }

  .partnerWithUs > .bottomContent > .block > img {
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    width: 150px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .partnerWithUs > .bottomContent > .block > h2 {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .partnerWithUs > .bottomContent > .block > p {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin-top: 10px;
  }

  .partnerWithUs > .footer {
    border-top: 1px solid rgba(0, 0, 0, 0);
    /* background-color: #fff; */
    /* width: 100%; */
  }
}
