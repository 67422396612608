.forwardandbackicons {
  transition: 0.3s !important;
  color: #fff;
  font-size: 50px !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.4); */
}

.forwardandbackicons:hover {
  cursor: pointer;
  color: #008080;
}

.slider3dcontainer {
  width: 80%;
  height: 600px;
  margin: 0 auto;
  margin-top: -100px !important;
}

@media screen and (max-width: 1366px) {
  .slider3dcontainer {
    height: 420px;
  }

  .forwardandbackicons {
    font-size: 30px !important;
    margin-top: -50px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
}

@media screen and (max-width: 768px) {
  .slider3dcontainer {
    width: 100%;
    height: 220px !important;
    max-height: 500px !important;
    margin-top: -20px !important;
  }

  .slideImg3d {
    max-width: 300px;
  }

  .forwardandbackicons {
    font-size: 30px !important;
    margin-top: -50px;
  }
}
