@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap");

.slider_Destinations {
  padding: 10px;
  max-width: 960px !important;
  outline: none;
}

.tile_Destinations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  outline: none;
  overflow: hidden;
  width: 960px;
}

.tile_Destinations > img {
  width: 950px;
  border-radius: 0px;
  margin: auto;
}

.tile_Destinations > h2 {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 19px;
}

@media screen and (max-width: 1366px) {
  .slider_Destinations {
    max-width: 820px;
  }

  .tile_Destinations {
    display: flex;
    flex-direction: column;
  }
  .tile_Destinations > img {
    width: 800px;
    margin: auto;
  }

  .tile_Destinations > h2 {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .tile_Destinations > img {
    width: 280px;
    margin: auto;
  }

  .tile_Destinations > h2 {
    font-size: 10px;

    text-align: center;
  }
}
