@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mate+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poiret+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Philosopher&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mate+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mate+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Exo+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arizonia&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,300;2,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Didact+Gothic&display=swap);
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active {
  background-color: #f4f4f4;
  height: 80px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.active::before {
  transition: 0.3ms;
}

.disable {
  display: none;
}

.stable {
  color: white;
}

.stable:hover {
  color: #008080;
}

.colorchange {
  color: #008080;
}

.colorchange:hover {
  color: #d0b49f;
}

.colorchange > .pressed {
  text-decoration: underline;
}

.stable > .pressed {
  text-decoration: underline;
}

.Wrapper_Container__3O36u {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.Title_Container__1yXnN {
  margin: 0 auto;
  padding: 0;
  text-transform: uppercase;
  width: 90%;
  text-align: center;
  font-size: 3.5rem;
}

.Subtitle_Container__23T7q {
  margin: 24px auto 0;
  padding: 0;
  width: 80%;
  text-align: center;
  font-size: 1.75rem;
}

section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-self: center;
  margin-bottom: 15px;
  background-color: #f3f3f3;
  background: url(/static/media/storyBack.501848bc.png);
  background-size: cover;
  overflow: hidden;
}

.story {
  flex: 0.5 1;
  display: flex;
  flex-direction: column;

  max-width: 900px;
  /* margin-left: 50px; */
  padding: 40px 20px;
}

.slideshow {
  flex: 0.5 1;
  max-width: 550px;
  background-color: #fff;
  border-radius: 10px;
  /* margin-left: -20px; */
  /* padding: 20px 10px; */
}

.story > h1 {
  /* font-family: "Staatliches", cursive; */

  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  /* font-family: 'Philosopher', sans-serif; */
  font-size: 50px;
  margin-bottom: 40px;
  color: #008080;
}

.story > .content > p {
  /* font-family: "Mate SC", serif; */
  line-height: 25px;
  font-family: "Raleway", sans-serif;
  text-align: justify;
  color: #383c3d;
  margin-top: 15px;
  line-height: 30px;
  font-size: 19px;
}

.special1 {
  color: #d0b49f;
  /* font-family: "Staatliches", cursive; */
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
}

@media screen and (max-width: 1366px) {
  section {
    background: url(/static/media/storyBack1366.04b0e4fd.png);
    background-size: cover;
  }

  .slideshow {
    /* margin-top: 120px; */
    width: 440px;
    max-width: 440px;
  }

  .story {
    max-width: 600px;
  }

  .story > .content > p {
    /* font-family: "Mate SC", serif; */
    line-height: 20px !important;
    font-family: "Raleway", sans-serif;
    text-align: justify;
    color: #383c3d;
    margin-top: 15px;

    font-size: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .slideshowMobile {
    display: none;
  }

  .story > .content > p {
    /* font-family: "Mate SC", serif; */
    line-height: 25px !important;
    font-family: "Raleway", sans-serif;
    text-align: justify;
    color: #383c3d;
    margin-top: 15px;

    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  section {
    display: flex;
    flex-direction: column;
  }

  .story {
    margin-left: 0;
  }

  .slideshow {
    width: 335px;
  }
}

.containerCover {
  width: 100%;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  /* margin-left: -55px; */
}

.slideCover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  max-width: 550px;
  border-radius: 10px;
  background-color: #fff;
  outline: none;
}

.slideCover img {
  width: 550px;
  max-width: 550px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.slideCover > h3 {
  font-family: "Raleway", sans-serif;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
}

.slideCover > p {
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  text-align: justify;
  text-align-last: center;
  padding: 20px 50px;
  line-height: 1.1rem;
  color: rgb(66, 66, 66);
}

@media screen and (max-width: 1366px) {
  .slideCover > img {
    width: 440px;
    max-width: 440px;
  }

  .slideCover > p {
    padding: 20px 20px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .slideCover > img {
    width: 335px;
  }

  .slideCover > h3 {
    font-size: 16px;
  }

  .slideCover > p {
    font-size: 12px;
  }
}

/*
.slickModule {
  max-width: 555px;
  width: 555px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: white;
 

  padding: 15px;
  transition: 0.3s;
  z-index: 10 !important;
 
}


.slickModule:hover {
  transform: translateY(5px);
}

.slickModule > .imageContainer {
  width: 560px;
  max-width: 560px !important;
}

.slickModule > .imageContainer > img {
  object-fit: contain;
  width: 500px;
  position: relative;
  border-radius: 5px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

@media screen and (max-width: 1366px) {
  .slickModule > .imageContainer > img {
    width: 380px;
  }

  .slickModule {
    width: 410px;
    max-width: 410px !important;
  }
}

.slickModule > .imageContainer > .overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.slickModule > .imageContainer:hover img {
  opacity: 0.7;
}

.slickModule > .imageContainer:hover .overlay {
  opacity: 1 !important;
}

.slickModule > .imageContainer > .overlay > .slickButton {
  padding: 15px 20px;
  background-color: royalblue;
  color: white;
  font-size: medium;
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  transition: 0.5s;
  text-decoration: none;
}

.slickModule > .imageContainer > .overlay > .slickButton:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .slickModule > .imageContainer {
    width: auto;
    object-fit: contain;
    overflow: hidden;
  }

  .slickModule > .imageContainer > .overlay > .slickButton {
    padding: 10px;
    background-color: royalblue;
    color: white;
    font-size: small;
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
    text-decoration: none;
  }

  .slickModule > .imageContainer > .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .slickModule {
    width: auto;
  }

  .slickModule > .imageContainer > img {
    width: 300px;
    object-fit: contain;
    overflow: hidden;
  }
}

.slickModule > h2 {
  margin: auto;
  text-align: center;
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  
  padding-top: 10px;
  font-size: large;
}

*/

.total {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slickModule {
  max-width: 560px;
  width: 560px;
  padding-bottom: 10px;
  background-color: white;
  margin: auto;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.slickModule > .imageContainer {
  max-width: 560px;
}

.slickModule > .imageContainer > img {
  object-fit: cover;
  width: 560px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .slickModule {
    max-width: 250px;
    width: 250px;
    padding-bottom: 10px !important;
    background-color: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }

  .slickModule > .imageContainer {
    max-width: 250px;
  }
  .slickModule > .imageContainer > img {
    object-fit: cover;
    /* width: 200px; */
    max-width: 280px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: auto;
  }
}

.slickModule > h2 {
  text-align: center;
  margin-top: 5px;
  font-family: "Raleway", sans-serif;
  color: rgba(0, 0, 0, 0.9);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.slickModule > .overlay {
  display: flex;
  justify-content: center;
}

.slickModule > .overlay > .slickButton {
  text-decoration: none;
  background-color: #008080;
  padding: 10px 30px;
  margin: auto;
  transition: 0.3s;
  color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.slickModule > .overlay > .slickButton:hover {
  cursor: pointer;
  background-color: #d0b49f;
}

@media screen and (max-width: 1366px) {
  .slickModule > .imageContainer > img {
    object-fit: cover;
    width: 390px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: auto;
  }

  .slickModule {
    max-width: 390px;
    width: 390px;
    padding-bottom: 10px;
    background-color: white;
    margin: auto;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }
}

@media screen and (max-width: 768px) {
  .slickModule > h2 {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-weight: 400;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f3f3f3;
  margin-top: -15px;
}

.container > h1 {
  margin: auto;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
  color: #008080;
}

.special {
  color: #d0b49f;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
}

.container > .slidersContainer {
  padding: 10px;
}

.container > .slidersContainer > .top {
  padding: 10px;
  margin-bottom: 10px;
}

.container > .slidersContainer > .bottom {
  padding: 10px;
  margin-bottom: 10px;
}

.container > .slidersContainer > .top > h2 {
  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  text-align: center;
  font-weight: 400;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
  /* background-color: ; */
  /* border-radius: 5px; */
  margin-bottom: 15px;
  padding: 10px;
  text-transform: uppercase;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.top__slider {
  margin: 5px;
}

.bottom__slider {
  margin: 5px;
}

.container > .slidersContainer > .bottom > h2 {
  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  text-align: center;
  font-weight: 400;
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
  /* background-color: ; */
  /* border-radius: 5px; */
  margin-bottom: 15px;
  padding: 10px;
  text-transform: uppercase;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

@media screen and (max-width: 768px) {
  .desktopSlider {
    display: none;
  }

  .container > .slidersContainer > .bottom > h2 {
    font-family: "Didact Gothic", sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin: auto;
    text-align: center;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    /* background-color: #191c1d; */
    /* border-radius: 5px; */
    margin-bottom: 15px;
    padding: 10px;
    text-transform: uppercase;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }

  .container > .slidersContainer > .top > h2 {
    font-family: "Didact Gothic", sans-serif;
    font-size: 15px;
    margin: auto;
    font-weight: 400;
    text-align: center;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    /* background-color: #191c1d; */
    /* border-radius: 5px; */
    margin-bottom: 15px;
    padding: 10px;
    text-transform: uppercase;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }
}

@media screen and (min-width: 961px) {
  .mobileSlider {
    display: none;
  }
}


.basicSlider {
  /* margin-top: -70px; */
}

@media screen and (min-width: 1000px) {
    .basicSliderMobile {
      display: none;
    }
}
@media screen and (max-width: 768px) {
  .basicSlider {
    display: none;
  }
}


.navbarFilter {
  display: flex;
  margin: auto;
  max-width: 60%;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid #008080;
  padding: 10px;
  border-radius: 30px;
  color: #008080;

  transition: 0.5s;
}

.link:hover {
  background-color: #008080;
  color: white;
}

@media screen and (max-width: 1366px) {
  .navbarFilter {
    flex-wrap: wrap;
  }

  .link {
    font-size: 12px;
    padding: 7px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 768px) {
  .navbarFilter {
    flex-wrap: wrap;
    max-width: 80%;
    justify-content: center;
  }

  .link {
    font-size: 12px;
    padding: 7px;
    margin-bottom: 8px;
  }
}



.maldivesSection {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
}

.maldivesSection > h1 {
  color: #008080;
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
}

.maldivesSection > h1 > span {
  color: #d0b49f;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
}

.maldivesSection > .contentContainer {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

.hotels {
  margin: auto;
  width: 100%;
}

.hotels > .Heading {
  font-family: "Didact Gothic", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 24px;
  text-align: left;
  margin-top: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
}

.hotels > .HotelsContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.hotels > .HotelsContainer > .row {
  display: flex;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .hotels > .HotelsContainer {
    display: flex;
  }
  .hotels > .Heading {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 400;
    /* font-style: italic; */
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: left;
    margin-top: 30px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
  }

  .hotels > .HotelsContainer > .row {
    display: flex;
    flex-direction: column;
  }
}

.MainContainer {
  /* padding: 0px; */
}

.wrapper {
  display: flex;

  justify-content: space-evenly;
  /* padding: px; */
  /* padding-left: 30px; */
  /* padding-right: 30px; */
}

.wrapper > .left {
  overflow: hidden;
  width: 400px;
  height: 680px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  background-color: #008080;
  background: url(/static/media/maldivesBack.6d3eb2f3.png);
  padding-left: -10px;
  /* margin-left: -30px; */
}

.wrapper > .left > .title {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 15px;
  margin-left: 10px;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  padding: 10px;
}

.wrapper > .left > .content {
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 12px !important;
}

.wrapper > .left > .image {
  position: absolute;
  bottom: 0;
  /* left: 0; */
  margin-bottom: 10px;
  display: none;
}

.wrapper > .right {
  width: 600px;
  padding: 20px;
}

.wrapper > .left > h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 19px;
}

.wrapper > .right > form > .input {
  border: 1px solid #f4f4f4;
  color: black;
  width: 94%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
  padding: 10px;
  border-radius: 5px;
  background-color: #f3f3f3;
  letter-spacing: 1px;
  transition: 0.3s;
  margin-bottom: 10px;
}

.wrapper > .right > form > .input::-webkit-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
}

.wrapper > .right > form > .input:-ms-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
}

.wrapper > .right > form > .input::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 200;
}

.wrapper > .right > form > .input:focus {
  background-color: white;
  outline: none;
  border-color: #008080;
}

.wrapper > .right > form > .inputField {
  display: none;
}
.buttons {
  margin: auto;
  /* width: 100%; */
  /* justify-content: space-evenly; */
  display: flex;
  /* position: absolute;
  bottom: 0; */
  font-family: "Raleway", sans-serif;
  margin-bottom: 10px;
}

.buttons > button {
  display: none;
}

.theButton {
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  background-color: #008080;
  color: white;
  border-radius: 15px;
  margin-right: 20px;
  transition: 0.3s;
}

.theButton:hover {
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .wrapper > .left {
    display: none;
  }
  .wrapper > .right {
    width: auto;
    /* padding: 40px; */
    padding-left: 20px !important;
  }

  .wrapper > .right > form > .input {
    border: 1px solid #f4f4f4;
    color: black;
    width: 90%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); */
    padding: 10px;
    border-radius: 5px;
    background-color: #f3f3f3;
    transition: 0.3s;
    margin-bottom: 10px;
  }
  .wrapper > .right > form {
    padding: 20px;
    max-width: 300px;
  }

  .buttons {
    position: relative;
  }
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f4f4f4;
  background-image: none;
}

.team::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.team > .heading > h1 {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
  color: #008080;
}

.team > .heading > h1 > span {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 50px;
  color: #d0b49f;
}
.containerModule {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.containerModuleSlider {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  max-height: 520px;
}

.card {
  position: relative;
  max-width: 350px;
  height: 450px;
  max-height: 450px;
  padding-top: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* background: rgba(255, 255, 255, 0.05); */
  background-color: #fff;
  margin-bottom: 20px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.card > .content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  transition: 0.5s;
  margin-bottom: 10px;
}

.card:hover > .content {
  opacity: 1;
}

.card > .content > .imgBx {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
  border: 5px solid rgba(0, 0, 0, 0.25);
}

.card > .content > .imgBx > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.card > .content > .contentBx > h3 {
  color: black;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1rem;
}

.card > .content > .contentBx > p {
  color: rgba(0, 0, 0, 0.8);
  padding: 10px 30px;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
  text-align-last: center;
  margin: 20px 0 15px;
  line-height: 1.1rem;
}

.card > .content > .contentBx > h3 > span {
  font-size: 11px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  text-transform: none;
  text-transform: initial;
}

.card > .sci {
  position: absolute;
  bottom: 50px;
  display: flex;
}

.card > .sci > li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.3s * var(mystyle));
}

.card:hover > .sci > li {
  transform: translateY(35px);
  opacity: 1;
}

.card > .sci > li > a {
  color: black;
  font-size: 24px;
  transition: 0.3s;
}

.card > .sci > li > a:hover {
  color: #008080;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .containerModuleSlider {
    width: 100%;
  }

  .card {
    position: relative;
    max-width: 300px;
    height: 400px;
    max-height: 400px;
    padding-top: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    /* background: rgba(255, 255, 255, 0.05); */
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }

  .card > .content > .imgBx > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px;
    object-fit: cover;
  }

  .card > .content > .imgBx {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 10px;
    overflow: hidden;
    border: 5px solid rgba(0, 0, 0, 0.25);
  }

  .card > .content > .contentBx > h3 {
    color: black;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    margin: 20px 0 15px;
    line-height: 1.1rem;
  }

  .card > .content > .contentBx > p {
    color: rgba(0, 0, 0, 0.8);
    padding: 10px 30px;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-size: 10px;
    text-align: justify;
    text-align-last: center;
    margin: 20px 0 15px;
    line-height: 1.1rem;
  }

  .card > .content > .contentBx > h3 > span {
    font-size: 11px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    text-transform: none;
    text-transform: initial;
  }
}

@media screen and (max-width: 768px) {
  .containerModule > .card {
    position: relative;
    width: 300px;
    height: 570px;
    padding-top: 20px;

    /* background: rgba(255, 255, 255, 0.05); */
    background-color: #fff;
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }

  .team > .heading > h1 {
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    font-size: 40px;
    color: #008080;
  }

  .team > .heading > h1 > span {
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    font-size: 40px;
    color: #d0b49f;
  }
}

.footerHome {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.1) 80%,
      #f3f3f3
    ),
    url(/static/media/footerBack.3b73bd88.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  max-height: 300px;
  background-color: #f3f3f3;
  margin-top: -20px;
}

.specx {
  font-weight: 400;
  font-family: "Cormorant Garamond", serif;
  color: #d0b49f;
}

.footerHome > .tophalf {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.footerHome > .tophalf > .main > .newsletter {
  padding: 40px;
  width: 500px;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  margin-top: -20px;
}

.footerHome > .tophalf > .main > .newsletter h3 {
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

.footerHome > .tophalf > .main .newsletter form {
  /* display: flex; */
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: auto;
}

.footerHome > .tophalf > .main .newsletter form .input {
  border: 1px solid #fff;
  font-family: "Raleway", sans-serif;

  width: 80%;
  padding: 10px;
  background-color: transparent;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: auto;

  /* border-radius: 10px; */
  transition: 0.3s;
}

.footerHome > .tophalf > .main .newsletter form .input::-webkit-input-placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerHome > .tophalf > .main .newsletter form .input:-ms-input-placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerHome > .tophalf > .main .newsletter form .input::placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerHome > .tophalf > .main .newsletter form .input:focus {
  outline: none;
  border-bottom: 2px solid #fff;
}

.footerHome > .tophalf > .main .newsletter form .button {
  border: 2px solid #fff;
  padding: 9px;
  background-color: #fff;
  color: black;
  text-transform: uppercase;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s;
}

.footerHome > .tophalf > .main .newsletter form .button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
}

.footerHome > .tophalf > .main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footerHome > .tophalf > .main .contentSection > h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #008080;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerHome > .tophalf > .main .contentSection > h1 span {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #d7be69;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerHome > .tophalf > .main > .contentSection h4 {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  text-align: center;
  /* margin-top: 5px; */
  /* text-transform: uppercase; */
  margin-top: 85px;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerHome > .tophalf > .main > .icons {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 400px !important;
  margin-right: -20px;
  margin-left: 100px;
}

.footerHome > .tophalf > .main > .icons > .iconsContainer {
  padding: 10px;
  display: flex;
  margin-left: 40px;
  justify-content: space-evenly;
  width: 300px !important;
  margin-top: 10px;
}

.footerHome > .tophalf > .main > .icons > h3 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  margin-top: -13px;
}

.footerHome > .tophalf > .main > .icons > .iconsContainer a .icon {
  font-size: 40px;
  border: 1px solid;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
  text-decoration: none;
  padding: 10px;
  color: #fff;
  margin-left: -20px !important;
  margin-right: -20px !important;
  transition: 0.4s;
  margin-top: -7px;
}

.footerHome > .tophalf > .main > .icons > .iconsContainer > a .icon:hover {
  color: #d0b49f;
  border: 2px solid #d0b49f;
  cursor: pointer;
}

.footerHome > .tophalf > .solutionSection {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.footerHome > .tophalf > .solutionSection h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
}

.footerHome > .tophalf > .solutionSection h3 > a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

.footerHome > .tophalf > .solutionSection h3 > a:hover {
  color: #008080;
  cursor: pointer;
}

#bottomNewCont {
  display: flex;
  width: 82%;
  margin: auto;
  justify-content: space-between;
  margin-top: 35px;
}

#leftSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  margin-left: -10px !important;
  text-align: center;
  text-transform: capitalize;
}

#rightSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
  margin-left: -10px !important;
  margin-top: 3px !important;
  text-transform: capitalize;
}

#rightSolution a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

#rightSolution a:hover {
  color: #008080;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .footerHome > .tophalf > .main > .icons {
    margin-left: 0px;
    margin-top: 40px;
  }
  .footerHome > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 400px;
    margin-top: 10px;
  }

  .footerHome > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  .footerHome > .tophalf > .main > .newsletter {
    padding: 40px;
    width: 400px;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    margin-top: -20px;
  }

  .footerHome > .tophalf > .main .newsletter form .input {
    border: 1px solid #fff;
    font-family: "Raleway", sans-serif;

    width: 60%;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin: auto;
    margin-left: 20px;
    /* border-radius: 10px; */
    transition: 0.3s;
  }

  .footerHome > .tophalf > .main .contentSection > h1 {
    margin-top: -95px;
  }

  #rightSolution {
    margin-top: -4px !important;
  }

  #leftSolution {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 768px) {
  .footerHome {
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.1) 80%,
        #f3f3f3
      ),
      url(/static/media/footerBack.3b73bd88.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    max-height: 400px;
  }

  .footerHome > .tophalf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .footerHome > .tophalf > .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .footerHome > .tophalf > .main > .icons {
    margin-left: -20px;
  }

  .footerHome > .tophalf > .main > .icons .iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerHome > .tophalf > .main > .icons .iconsContainer > a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    transition: 0.4s;
    margin-right: 15px;
    margin-left: 15px;
  }
  .footerHome > .tophalf > .main .newsletter form {
    justify-content: center;
    align-items: center;

    margin: auto;
    width: 100%;
  }

  .footerHome > .tophalf > .main .newsletter form .input {
    width: 50%;
    margin-left: 40px;
    align-self: center;
  }

  .footerHome > .tophalf > h3 > a {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerHome > .tophalf > .solutionSection {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .footerHome > .tophalf > .main .contentSection > h1 {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerHome > .tophalf > .main .contentSection > h1 span {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerHome > .tophalf > .main > .contentSection h4 {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    /* text-transform: uppercase; */

    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  }

  .footerHome > .tophalf > .main > .newsletter h3 {
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  }

  .footerHome > .tophalf > .solutionSection h3 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-size: 10px;
    text-align: center;
  }

  .footerHome > .tophalf > .solutionSection h3 > a {
    font-family: "Raleway", sans-serif;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerHome > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 800px;
    margin-top: 10px;
  }

  .footerHome > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  #bottomNewCont {
    width: 100% !important;
    margin: auto;
    flex-direction: column;
    margin-top: 0px;
  }

  #leftSolution {
    text-align: center;
    font-size: 10px !important;
    margin-left: 0px !important;
    margin-bottom: 5px;
  }

  #rightSolution {
    text-align: center;
    font-size: 10px !important;
  }
}

.navbar {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    height: 75px;
    background-color: transparent;
    z-index: 999 !important;
    /* box-shadow: 0 5px 10px rgba(0,0,0,0.2); */
}

.navbar > .image {
    padding: 5px;
}

.navbar > .image > img {
    display: none;
    width: 160px;
    margin-left: 20px;
    z-index: 40;
    -webkit-filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
  filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
}

.navbar > .image > .white {
    display: flex;
    width: 160px;
    margin-left: 20px;
    z-index: 40;
    -webkit-filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
  filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
}

.navbar > .contents {
    display: flex;
}

.navbar > .contents > .links {
    margin-right: -40px;
}

.navbar > .contents > .links > .spy {
    display: flex;
    justify-content: space-between;
    padding: 29px;
    
}

.navbar > .contents > .links > .spy >  li {
    list-style: none;
    margin-right: 60px;
   
    
}

.is-current {
    color: #D0B49f !important;
    font-weight: 600 !important;
    /* border-bottom: 3px solid #D0B49F; */
}

.navbar > .contents > .links > .spy > li > .oneLink {
    text-decoration: none;
    color: white;
    
    text-transform: uppercase;
    text-shadow: 0 0 10px rgba(0,0,0,0.8);
    /* font-weight: 400; */
    transition: 0.3s;
}


.navbar > .contents > .links > .spy > li > .oneLink:hover{
    cursor: pointer;
    color: #D0B49F;
    /* border-bottom: 3px solid #D0B49F; */
    transition: border-bottom 0.2s ease-in-out;
}



.navbar > .contents > .icons {
    display: flex;
    padding: 25px;
}
.navbar > .contents > .icons > a {
    text-decoration: none;
    color: #008080;
}

.navbar > .contents > .icons > a > .icon {
    margin-left: 10px;
    transition: 0.3s;
}

.navbar > .contents > .icons > a > .icon:hover {
    color: #D0B49F;
    cursor: pointer;
}

.mobileView {
    display: none;
}


/*********************************/

.navbar--scrolled {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    height: 75px;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

.navbar--scrolled > .image {
    padding: 5px;
}

.navbar--scrolled > .image > img {
    width: 130px;
    margin-left: 20px;
    z-index: 40;
}

.navbar--scrolled > .image > .white {
    display: none;
}

.navbar--scrolled > .contents {
    display: flex;
}

.navbar--scrolled > .contents > .links {
    margin-right: -40px;
}

.navbar--scrolled > .contents > .links > .spy {
    display: flex;
    justify-content: space-between;
    padding: 29px;
}

.navbar--scrolled > .contents > .links > .spy >  li {
    list-style: none;
    margin-right: 60px;
    
}

.is-current {
    color: #D0B49F !important;
    /* border-bottom: 3px solid #D0B49F; */
    font-weight: 600;
}

.navbar--scrolled > .contents > .links > .spy > li > .oneLink {
    text-decoration: none;
    color: #008080;
    text-transform: uppercase;
    /* text-shadow: 0 0 10px rgba(0,0,0,0.8); */
    /* font-weight: 400; */
    transition: 0.3s;
}

.navbar--scrolled > .contents > .links > .spy > li > .oneLink:hover{
    cursor: pointer;
    color: #D0B49F;
    /* border-bottom: 3px solid #D0B49F; */
    transition: border-bottom 0.2s ease-in-out;
}



.navbar--scrolled > .contents > .icons {
    display: flex;
    padding: 25px;
}
.navbar--scrolled > .contents > .icons > a {
    text-decoration: none;
    color: #008080;
}

.navbar--scrolled > .contents > .icons > a > .icon {
    margin-left: 10px;
    transition: 0.3s;
}

.navbar--scrolled > .contents > .icons > a > .icon:hover {
    color: #D0B49F;
    cursor: pointer;
}

.mobileView {
    display: none;
}

@media screen and (max-width: 1366px) {
    .navbar {
        height: 60px;
    }

    .navbar > .image > .white {
        display: flex;
        width: 120px;
        margin-left: 20px;
        z-index: 40;
        -webkit-filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
      filter: drop-shadow(0px 0px 15px rgba(0,0,0,0.7));
    }

    .navbar > .contents > .links > .spy >  li {
        margin-right: 40px;
    }

    
    .navbar > .contents > .links > .spy > li > .oneLink {
        font-size: 12px;
    }
}


@media screen and (max-width: 768px) {
    .navbar {
        display: none;
    }

    .navbar--scrolled {
        display: none;
    }

    .mobileView {
        display: flex;
        justify-content: space-between;
        position: fixed;
        background-color: #fff;
        z-index: 99;
        padding: 5px;
        height: 65px;
        width: 100%;
    }

    .mobileView > .icon {
        font-size: 35px;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: -30px;
    }

    .mobileView > img {
        width: 120px;
        margin: auto;
        
    }
}
#navbar {
  max-height: 70px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
}

#navbar > .image > .imglink > .white {
  width: 130px;
  margin-top: 5px;
  margin-left: 100px;
}

#navbar > .contents > .links > .spy {
  display: flex;
  list-style: none;
  padding: 25px;
}

#navbar > .contents > .links > .spy > li {
  margin-left: 15px;
  margin-right: 15px;
}

#navbar > .contents > .links > .spy > li > .oneLink {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: 0.3s;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
}

#navbar > .contents > .links > .spy > li > .oneLink:hover {
  cursor: pointer;
  color: #d0b49f;
}

.active {
  visibility: visible;
  transition: all 0.5s;
}
.hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}

.mobileView {
  display: none;
}

.is-current {
  font-weight: 600 !important;
}

@media screen and (max-width: 1366px) {
  #navbar > .contents > .links > .spy > li > .oneLink {
    text-decoration: none;
    color: #fff;
    transition: 0.3s;
    font-size: 12px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
  }

  #navbar > .image > .imglink > .white {
    width: 110px;
    margin-top: 5px;
    margin-left: 50px;
  }

  #navbar {
    max-height: 60px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #navbar > .contents > .links > .spy {
    display: flex;
    list-style: none;
    padding: 20px;
  }

  .mobileView {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #navbar {
    display: none;
  }

  .mobileView {
    display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #fff;
    z-index: 99;
    padding: 5px;
    height: 65px;
    width: 100%;
  }

  .mobileView > .icon {
    font-size: 35px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: -30px;
  }

  .mobileView > img {
    width: 120px;
    margin: auto;
  }
}

.dropdown-li {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 5px;
}

.dropdown-li:hover {
  background-color: #008080;
}

.dropdown-ul {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  margin-top: 10px;
  width: 250px;
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style: none;
  border-radius: 5px;
  opacity: 0;
  transform: translate(-10px);
  transition: all 0.4s ease;
}

.dropdown-a {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  padding: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.droptoggler:focus + .dropdown-ul {
  opacity: 1;
  transform: translate(0px);
}

@media screen and (max-width: 1366px) {
  .dropdown-a {
    font-size: 10px;
    padding: 5px;
  }

  .dropdown-ul {
    height: 100px;
    width: 150px;
  }
}

.superContainer_Destinations {
  display: flex;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
  border-radius: 10px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 90vh !important;
  width: 91vw;
  margin-bottom: 20px;
}

.superContainer_Destinations > .navSlider {
  max-width: 1900px;
  width: 1900px;
  padding: 20px;
}

.superContainer_Destinations > .navSliderMobile {
  display: none;
}

.superContainer_Destinations > .navSlider .box {
  display: flex;

  width: 50px;
  margin-right: 5px;
}

.superContainer_Destinations > .navSlider .navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  width: 110px;
  max-width: 355px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border-left: 0px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.superContainer_Destinations > .navSlider .navxx:hover {
  cursor: pointer;
  border-left: 2px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.superContainer_Destinations > .navSlider .navxx > .dayNo {
  font-weight: 600;
  font-family: "Raleway", sans-serif !important;
  font-weight: 400;
  color: #008080;
  text-align: center;
  line-height: 1rem;
  font-size: 12px;
  margin-bottom: 3px;
}

.superContainer_Destinations > .navSlider .navxx > .dayTour {
  font-size: 12px;
  text-align: center;

  color: gray;
}

.superContainer_Destinations > .main {
  flex: 0.6 1;
  max-width: 1000px;
}

.superContainer_Destinations > .mainMobile {
  display: none;
}

.superContainer_Destinations > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Destinations {
    height: -webkit-max-content !important;
    height: -moz-max-content !important;
    height: max-content !important;
    max-height: 135vh !important;
  }
  .superContainer_Destinations > .mainMobile {
    display: none;
  }
  .superContainer_Destinations > .navSlider {
    width: 1400px;
  }

  .superContainer_Destinations > .navSlider .navxx {
    width: 85px;
  }

  .superContainer_Destinations > .navSlider .navxx > .dayNo {
    font-size: 10px;
    line-height: 0.9rem;
  }

  .superContainer_Destinations > .main {
    max-width: 860px;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Destinations {
    display: none;
  }

  .superContainer_Destinations > .navSlider {
    display: none;
  }
  .superContainer_Destinations > .mainMobile {
    display: none;
  }

  .superContainer_Destinations > .main {
    display: none;
  }
}

.slider {
  padding: 10px;
  max-width: 730px !important;
}

.tile {
  display: flex;
  flex-direction: column;
  max-width: 730px;

  overflow: hidden;
  width: 530px;
}

.tile > img {
  width: 710px;
  border-radius: 5px;
}

.tile > h2 {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10px;
  font-size: 15px;
}

@media screen and (max-width: 1366px) {
  .slider {
    max-width: 600px;
  }

  .tile {
    display: flex;
    flex-direction: column;
  }
  .tile > img {
    width: 700px;
    margin: auto;
  }

  .tile > h2 {
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .tile > img {
    width: 280px;
    margin: auto;
  }

  .tile > h2 {
    font-size: 10px;

    text-align: center;
  }
}

.tour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 750px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  transition: 0.4s;
  border-top: 3px solid #3498db;
  overflow: hidden;
}

.tour:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.tour > .line {
  display: flex;
  margin-bottom: 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 3px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.tour > .line h3 {
  text-align: center;
  text-transform: uppercase;
  color: #008080;
  white-space: normal;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 13px;
  margin-right: 10px;
}

.tour > .line h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
}

.tour > p {
  font-family: "Raleway", sans-serif;
  padding: 7px;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  font-size: 15px;
}

.tour > .tourSlides {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1366px) {
  .tour {
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  .tour {
    max-width: 300px;
  }

  .tour > .line {
    display: flex;
    flex-direction: column;
  }

  .tour > .line h3 {
    text-align: center;
    text-transform: uppercase;
    color: #008080;
    white-space: normal;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    margin-right: 10px;
  }

  .tour > .line h2 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
  }

  .tour > p {
    font-size: 12px;
  }
}

.tour_Destinations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 960px !important;
  width: 960px !important;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 0px;
  margin: 20px;
  transition: 0.4s;
  border: none;
  overflow: hidden;
}

.tour_Destinations:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.tour_Destinations > .line {
  display: flex;
  margin-bottom: 3px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 3px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.tour_Destinations > .line h3 {
  text-align: center;
  text-transform: uppercase;
  color: #008080;
  white-space: normal;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 13px;
  margin-right: 10px;
}

.tour_Destinations > .line h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
}

.tour_Destinations > p {
  font-family: "Raleway", sans-serif;
  padding: 7px;
  border-radius: 5px;
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  font-size: 15px;
}

.tour_Destinations > .slides {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1366px) {
  .tour_Destinations {
    max-width: 820px !important;
    width: 820px !important;
  }
}

@media screen and (max-width: 768px) {
  .tour_Destinations {
    display: flex;
    max-width: 300px !important;
    width: 300px !important;
  }

  .tour_Destinations > .line {
    display: flex;
    flex-direction: column;
  }

  .tour_Destinations > .line h3 {
    text-align: center;
    text-transform: uppercase;
    color: #008080;
    white-space: normal;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    margin-right: 10px;
  }

  .tour_Destinations > .line h2 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
  }

  .tour_Destinations > p {
    font-size: 12px;
  }
}

.slider_Destinations {
  padding: 10px;
  max-width: 960px !important;
  outline: none;
}

.tile_Destinations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  outline: none;
  overflow: hidden;
  width: 960px;
}

.tile_Destinations > img {
  width: 950px;
  border-radius: 0px;
  margin: auto;
}

.tile_Destinations > h2 {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 19px;
}

@media screen and (max-width: 1366px) {
  .slider_Destinations {
    max-width: 820px;
  }

  .tile_Destinations {
    display: flex;
    flex-direction: column;
  }
  .tile_Destinations > img {
    width: 800px;
    margin: auto;
  }

  .tile_Destinations > h2 {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .tile_Destinations > img {
    width: 280px;
    margin: auto;
  }

  .tile_Destinations > h2 {
    font-size: 10px;

    text-align: center;
  }
}

.superDesContainerMobile {
  display: none;
}

@media screen and (max-width: 1366px) {
  .superDesContainerMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superDesContainerMobile {
    display: flex;
    max-width: 350px !important;
    width: 350px !important;
    height: 50vh !important;
  }

  .superDesContainerMobile > .mainMobile_Destinations {
    display: flex;
    max-width: 350px !important;
    width: 350px !important;
  }
}

.superContainer_Dimension {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 90vh !important;
}

.superContainer_Dimension > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Dimension > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Dimension > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Dimension > .mainMobile {
  display: none;
}

.superContainer_Dimension > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Dimension {
    height: 135vh !important;
    max-height: 135vh !important;
  }
  .superContainer_Dimension > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Dimension {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Dimension > .navSlider {
    display: none;
  }
  .superContainer_Dimension > .mainMobile {
    display: flex;
  }

  .superContainer_Dimension > .main {
    display: none;
  }
}

.contDest {
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.8) 80%,
      #f3f3f3
    ),
    url(/static/media/destBackNew.8c753ca8.png);

  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1366px) {
  .contDest {
    height: 110vh;
  }
}

@media screen and (max-width: 768px) {
  .contDest {
    height: 55vh;
  }
}

.sliderContainerAwesome {
  background-size: cover;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  background-color: transparent !important;
}

@media screen and (max-width: 1366px) {
  .sliderContainerAwesome {
    max-width: 780px !important;
  }

  .slideDiv {
    max-width: 740px !important;
  }

  .slideDiv > img {
    max-width: 650px !important;
    margin-left: -10px;
  }
}

@media screen and (max-width: 768px) {
  .sliderContainerAwesome {
    max-width: 280px !important;
  }

  .slideDiv {
    max-width: 280px !important;
  }

  .slideDiv > img {
    max-width: 280px !important;
  }
}

.forwardandbackicons {
  transition: 0.3s !important;
  color: #fff;
  font-size: 50px !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.4); */
}

.forwardandbackicons:hover {
  cursor: pointer;
  color: #008080;
}

.slider3dcontainer {
  width: 80%;
  height: 600px;
  margin: 0 auto;
  margin-top: -100px !important;
}

@media screen and (max-width: 1366px) {
  .slider3dcontainer {
    height: 420px;
  }

  .forwardandbackicons {
    font-size: 30px !important;
    margin-top: -50px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
}

@media screen and (max-width: 768px) {
  .slider3dcontainer {
    width: 100%;
    height: 220px !important;
    max-height: 500px !important;
    margin-top: -20px !important;
  }

  .slideImg3d {
    max-width: 300px;
  }

  .forwardandbackicons {
    font-size: 30px !important;
    margin-top: -50px;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerHistory {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/historycover.7a466bde.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerHistory > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerHistory > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerHistory > .rightSide {
  display: flex;
  flex: 0.6 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerHistory > .rightSide > h3 {
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerHistory > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerHistory > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  display: none;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;

  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.fixedAnchorButton {
  max-width: 250px;
  padding: 10px 25px;
  padding-top: 12px;
  text-align: center;
  text-decoration: none;
  border: none;
  position: fixed;
  z-index: 10;
  margin-top: 400px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  top: 0;
  right: 0;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  transition: 0.4s;
}

.fixedAnchorButton:hover {
  cursor: pointer;
  background-color: #fff;
  color: #000;
}

.sriLanka_Package > .headerHistory > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > .topic {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .topic > .special {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 50px;
  color: #d0b49f;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #f3f3f3;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  background-color: #fff;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Raleway", sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Raleway", sans-serif !important;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: -3px !important;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

.SLBullets {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: auto;
  background-color: #f3f3f3;
}

.SLBullets > li {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin-left: 20px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-size: 14px;
}

.SLBullets > li > span {
  color: red;
}

.sriLanka_Package > .packageDes_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  padding: 10px;
}

.sriLanka_Package > .packageDes_Container > .packageDes {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  width: 60%;
  text-align: center;
  line-height: 1.8rem;
  color: gray;
  font-weight: 400;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}
.sriLanka_Package > .packageDes_Container > .packageDes > span {
  font-family: "Arizonia", cursive;
  font-size: 40px;
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerHistory {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/historycover.7a466bde.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerHistory > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  /* .sriLanka_Package > .headerHistory > .leftSide > img {
        
        width: 300px;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,0.7);
        
    } */

  .sriLanka_Package > .headerHistory > .leftSide > img {
    width: 300px !important;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerHistory > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerHistory > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerHistory > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerHistory > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }

  .sriLanka_Package > .hotels > .topic {
    font-size: 50px;
  }

  .sriLanka_Package > .hotels > .topic > .special {
    font-size: 50px;
  }

  .sriLanka_Package > .packageDes_Container > .packageDes {
    line-height: 1.4rem;
  }

  .fixedAnchorButton {
    font-size: 12px;
    margin-top: 250px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerHistory {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/history.61f957e1.jpg);
  }
  .sriLanka_Package > .headerHistory > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerHistory > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerHistory > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerHistory > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerHistory > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .hotels > .topic {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .topic > .special {
    font-size: 30px;
  }

  .sriLanka_Package > .headerHistory > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }

  .SLBullets {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: auto;
    background-color: #f3f3f3;
  }

  .SLBullets > li {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 20px;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    font-size: 10px;
  }

  .SLBullets > li > span {
    color: red;
  }

  .sriLanka_Package > .packageDes_Container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    padding: 15px;
  }

  .sriLanka_Package > .packageDes_Container > .packageDes {
    font-family: "Raleway", sans-serif;
    font-size: 11px;
    width: 100%;
    text-align: justify;
    text-align-last: center;
    line-height: 1.6rem;
    color: gray;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  }
  .sriLanka_Package > .packageDes_Container > .packageDes > span {
    font-family: "Arizonia", cursive;
    font-size: 40px;
  }

  .fixedAnchorButton {
    font-size: 12px;
  }
}

.superContainer_History {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 65vh !important;
}

.superContainer_History > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_History > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #008080;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6) !important;
}

.superContainer_History > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_History > .mainMobile {
  display: none;
}

.superContainer_History > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_History {
    height: 100vh !important;
    max-height: 100vh !important;
  }
  .superContainer_History > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_History {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_History > .navSlider {
    display: none;
  }
  .superContainer_History > .mainMobile {
    display: flex;
  }

  .superContainer_History > .main {
    display: none;
  }
}

.collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  /* background-color: #fff; */
  border-radius: 10px;
}

.collection > h3 {
  font-family: "Cormorant Garamond", serif;
  font-size: 19px;
  margin: auto;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

@media screen and (max-width: 1366px) {
  .collection {
    width: 100%;
  }

  .collection > h3 {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.collectionNav {
  display: flex;
  max-width: 600px;
  margin-bottom: 20px;
  padding: 10px;
}

.collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.collectionNav > .linkx > span {
  background-color: #008080;
  color: #fff;
  font-size: 14px;
  padding: 4px;
  margin-top: 5px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  transition: 0.3s;
  border-radius: 5px;
  width: 70px;
}

.collectionNav > .linkx > span > .icon {
  font-size: 13px;
}

.collectionNav > .linkx:hover > span {
  background-color: #fff;
  color: #008080;
}

@media screen and (max-width: 768px) {
  .collectionNav {
    display: flex;
    overflow: scroll;
    max-width: none;
    flex-wrap: wrap;
  }

  .collectionNav > .linkx {
    margin-right: 0;
    margin-bottom: 10px;
    max-width: 100px;
    font-size: 12px !important;
  }
}

.footerSL {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.1) 80%,
      #f3f3f3
    ),
    url(/static/media/footerBackSriLanka.817ada7c.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  max-height: 300px;
  background-color: #f3f3f3;
  margin-top: -20px;
}

.specx {
  font-weight: 400;
  font-family: "Cormorant Garamond", serif;
  color: #d0b49f;
}

.footerSL > .tophalf {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.footerSL > .tophalf > .main > .newsletter {
  padding: 40px;
  width: 500px;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  margin-top: -20px;
}

.footerSL > .tophalf > .main > .newsletter h3 {
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

.footerSL > .tophalf > .main .newsletter form {
  /* display: flex; */
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: auto;
}

.footerSL > .tophalf > .main .newsletter form .input {
  border: 1px solid #fff;
  font-family: "Raleway", sans-serif;

  width: 80%;
  padding: 10px;
  background-color: transparent;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: auto;

  /* border-radius: 10px; */
  transition: 0.3s;
}

.footerSL > .tophalf > .main .newsletter form .input::-webkit-input-placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerSL > .tophalf > .main .newsletter form .input:-ms-input-placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerSL > .tophalf > .main .newsletter form .input::placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerSL > .tophalf > .main .newsletter form .input:focus {
  outline: none;
  border-bottom: 2px solid #fff;
}

.footerSL > .tophalf > .main .newsletter form .button {
  border: 2px solid #fff;
  padding: 9px;
  background-color: #fff;
  color: black;
  text-transform: uppercase;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s;
}

.footerSL > .tophalf > .main .newsletter form .button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
}

.footerSL > .tophalf > .main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footerSL > .tophalf > .main .contentSection > h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #008080;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerSL > .tophalf > .main .contentSection > h1 span {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #d7be69;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerSL > .tophalf > .main > .contentSection h4 {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  text-align: center;
  /* margin-top: 5px; */
  /* text-transform: uppercase; */
  margin-top: 85px;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerSL > .tophalf > .main > .icons {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 400px !important;
  margin-right: -20px;
  margin-left: 100px;
}

.footerSL > .tophalf > .main > .icons > .iconsContainer {
  padding: 10px;
  display: flex;
  margin-left: 40px;
  justify-content: space-evenly;
  width: 300px !important;
  margin-top: 10px;
}

.footerSL > .tophalf > .main > .icons > h3 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  margin-top: -13px;
}

.footerSL > .tophalf > .main > .icons > .iconsContainer a .icon {
  font-size: 40px;
  border: 1px solid;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
  text-decoration: none;
  padding: 10px;
  color: #fff;
  margin-left: -20px !important;
  margin-right: -20px !important;
  transition: 0.4s;
  margin-top: -7px;
}

.footerSL > .tophalf > .main > .icons > .iconsContainer > a .icon:hover {
  color: #d0b49f;
  border: 2px solid #d0b49f;
  cursor: pointer;
}

.footerSL > .tophalf > .solutionSection {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.footerSL > .tophalf > .solutionSection h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
}

.footerSL > .tophalf > .solutionSection h3 > a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

.footerSL > .tophalf > .solutionSection h3 > a:hover {
  color: #008080;
  cursor: pointer;
}

#bottomNewCont {
  display: flex;
  width: 82%;
  margin: auto;
  justify-content: space-between;
  margin-top: 35px;
}

#leftSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  margin-left: -10px !important;
  text-align: center;
  text-transform: capitalize;
}

#rightSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
  margin-left: -10px !important;
  margin-top: 3px !important;
  text-transform: capitalize;
}

#rightSolution a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

#rightSolution a:hover {
  color: #008080;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .footerSL > .tophalf > .main > .icons {
    margin-left: 0px;
    margin-top: 40px;
  }
  .footerSL > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 400px;
    margin-top: 10px;
  }

  .footerSL > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  .footerSL > .tophalf > .main > .newsletter {
    padding: 40px;
    width: 400px;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    margin-top: -20px;
  }

  .footerSL > .tophalf > .main .newsletter form .input {
    border: 1px solid #fff;
    font-family: "Raleway", sans-serif;

    width: 60%;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin: auto;
    margin-left: 20px;
    /* border-radius: 10px; */
    transition: 0.3s;
  }

  .footerSL > .tophalf > .main .contentSection > h1 {
    margin-top: -95px;
  }

  #rightSolution {
    margin-top: -4px !important;
  }

  #leftSolution {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 768px) {
  .footerSL {
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.1) 80%,
        #f3f3f3
      ),
      url(/static/media/footerBackSriLanka.817ada7c.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    max-height: 400px;
  }

  .footerSL > .tophalf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .footerSL > .tophalf > .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .footerSL > .tophalf > .main > .icons {
    margin-left: -20px;
  }

  .footerSL > .tophalf > .main > .icons .iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerSL > .tophalf > .main > .icons .iconsContainer > a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    transition: 0.4s;
    margin-right: 15px;
    margin-left: 15px;
  }
  .footerSL > .tophalf > .main .newsletter form {
    justify-content: center;
    align-items: center;

    margin: auto;
    width: 100%;
  }

  .footerSL > .tophalf > .main .newsletter form .input {
    width: 50%;
    margin-left: 40px;
    align-self: center;
  }

  .footerSL > .tophalf > h3 > a {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerSL > .tophalf > .solutionSection {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .footerSL > .tophalf > .main .contentSection > h1 {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerSL > .tophalf > .main .contentSection > h1 span {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerSL > .tophalf > .main > .contentSection h4 {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    /* text-transform: uppercase; */

    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  }

  .footerSL > .tophalf > .main > .newsletter h3 {
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  }

  .footerSL > .tophalf > .solutionSection h3 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-size: 10px;
    text-align: center;
  }

  .footerSL > .tophalf > .solutionSection h3 > a {
    font-family: "Raleway", sans-serif;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerSL > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 800px;
    margin-top: 10px;
  }

  .footerSL > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  #bottomNewCont {
    width: 100% !important;
    margin: auto;
    flex-direction: column;
    margin-top: 0px;
  }

  #leftSolution {
    text-align: center;
    font-size: 10px !important;
    margin-left: 0px !important;
    margin-bottom: 5px;
  }

  #rightSolution {
    text-align: center;
    font-size: 10px !important;
  }
}

.partnerWithUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
}

.partnerWithUs > .navBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.partnerWithUs > .indexofz {
  z-index: 4;
}

.partnerWithUs > .headerContent {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url(/static/media/partner.c042eb4c.jpg);

  background-size: cover;
  width: 100%;
  height: 70vh;
  background-repeat: no-repeat;
  background-blend-mode: darken;
}

.partnerWithUs > .headerContent > h1 {
  color: #008080;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-align: center;
  margin-top: 250px;
  font-size: 60px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.partnerWithUs > .headerContent > h1 > span {
  color: #d0b49f;
  text-transform: uppercase;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  text-align: center;
  font-size: 60px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.partnerWithUs > .formContainer {
  background-color: #fff;
  margin-top: -280px;
  margin-bottom: 50px;
  width: 49%;
  display: flex;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  padding: 15px 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.partnerWithUs > .formContainer > form {
  display: flex;
  flex-direction: column;
}

.partnerWithUs > .formContainer > form > .inputField {
  padding: 17px 10px;
  width: 900px;
  max-width: 900px;
  min-width: 900px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
  font-weight: 400;
  margin-bottom: 10px;
  z-index: 0;
}
.partnerWithUs > .formContainer > form > .inputField::-webkit-input-placeholder {
  font-family: "Raleway", sans-serif;

  font-weight: 400;
}
.partnerWithUs > .formContainer > form > .inputField:-ms-input-placeholder {
  font-family: "Raleway", sans-serif;

  font-weight: 400;
}
.partnerWithUs > .formContainer > form > .inputField::placeholder {
  font-family: "Raleway", sans-serif;

  font-weight: 400;
}

.partnerWithUs > .formContainer > form > .inputField:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 3pt #008080;
}

.partnerWithUs > .formContainer > form > .seperate {
  display: flex;
  z-index: 1 !important;
  /* justify-content: space-between; */
}

.inputField1 {
  padding: 17px 10px;
  width: 100%;
  margin-right: 12px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Century-Gothic", sans-serif;
  transition: 0.3s;
  margin-bottom: 10px;
  z-index: 0;
}
.inputField2 {
  padding: 17px 10px;
  width: 100%;

  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Century-Gothic", sans-serif;
  transition: 0.3s;
  margin-bottom: 10px;
}

.inputField1:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 3pt #008080;
}

.inputField2:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 3pt #008080;
}

.label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 14px;
  margin-left: 4px;
  margin-right: 8px;
}

.checkarea {
  display: flex;
  padding-top: 4px;
  margin-bottom: 10px;
}

.checker {
  margin-top: 3px;
}

.partnerSubmit {
  padding: 15px;
  width: 200px;
  margin: auto;
  border: none;
  background-color: #008080;
  color: #fff;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 10px;
  font-size: 18px;
  text-transform: uppercase;
  transition: 0.4s;
}

.partnerSubmit:hover {
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.partnerWithUs > .footer {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  /* background-color: #fff; */
  /* width: 100%; */
}

.partnerWithUs > .bottomContent {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  /* width: 40vw; */
  margin-bottom: 20px;
}
.partnerWithUs > .bottomContent > .block {
  background-color: #fff;
  padding: 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  width: 400px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.partnerWithUs > .bottomContent > .block > img {
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  width: 200px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

.partnerWithUs > .bottomContent > .block > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
}

.partnerWithUs > .bottomContent > .block > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  margin-top: 10px;
}

.botTitle {
  font-family: "century-gothic", sans-serif;
  margin-bottom: 10px;
  font-weight: 400;
  color: #008080;
  text-align: center;
  font-size: 35px;
}

.specialPart {
  color: #d0b49f;
}

@media screen and (max-width: 1366px) {
  .partnerWithUs > .headerContent > h1 {
    color: #008080;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;

    font-weight: 400;
    text-align: center;
    margin-top: 200px;
    font-size: 50px;
  }
  .partnerWithUs > .headerContent > h1 > span {
    color: #d0b49f;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;

    font-weight: 400;
    text-align: center;
    margin-top: 200px;
    font-size: 50px;
  }

  .partnerWithUs > .formContainer {
    background-color: #fff;
    margin-top: -150px;
    margin-bottom: 50px;
    width: 70%;
    display: flex;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 15px 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .partnerWithUs > .headerContent {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
      url(/static/media/partner.c042eb4c.jpg);

    background-size: contain;
    width: 700px;
    height: 35vh;
    background-repeat: no-repeat;
    background-blend-mode: darken;
  }

  .partnerWithUs > .headerContent > h1 {
    color: #008080;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
  }
  .partnerWithUs > .headerContent > h1 > span {
    color: #d0b49f;
    text-transform: uppercase;
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
  }

  .partnerWithUs > .formContainer {
    background-color: #fff;
    margin-top: -50px;
    margin-bottom: 50px;
    width: 95%;
    display: flex;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 15px 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
  }

  .partnerWithUs > .formContainer > form {
    max-width: 120%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .partnerWithUs > .formContainer > form > .inputField {
    padding: 17px 10px;
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-family: "Century-Gothic", sans-serif;
    transition: 0.3s;
    margin-bottom: 10px;
  }

  .partnerWithUs > .formContainer > form > .seperate {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 300px;
  }

  .botTitle {
    font-family: "century-gothic", sans-serif;
    margin-bottom: 10px;
    font-weight: 400;
    color: #008080;
    text-align: center;
    font-size: 25px;
  }

  .partnerWithUs > .bottomContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* width: 40vw; */
    margin-bottom: 20px;
  }
  .partnerWithUs > .bottomContent > .block {
    background-color: #fff;
    padding: 70px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    width: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
  }

  .partnerWithUs > .bottomContent > .block > img {
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    width: 150px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .partnerWithUs > .bottomContent > .block > h2 {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .partnerWithUs > .bottomContent > .block > p {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin-top: 10px;
  }

  .partnerWithUs > .footer {
    border-top: 1px solid rgba(0, 0, 0, 0);
    /* background-color: #fff; */
    /* width: 100%; */
  }
}

.footerPartners {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.1) 80%,
      #f3f3f3
    ),
    url(/static/media/footerBackPartners.25a3d4d1.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  max-height: 300px;
  background-color: #f3f3f3;
  margin-top: -20px;
}

.specx {
  font-weight: 400;
  font-family: "Cormorant Garamond", serif;
  color: #d0b49f;
}

.footerPartners > .tophalf {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}

.footerPartners > .tophalf > .main > .newsletter {
  padding: 40px;
  width: 500px;
  justify-content: center;
  align-items: center;
  margin-bottom: -20px;
  margin-top: -20px;
}

.footerPartners > .tophalf > .main > .newsletter h3 {
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

  text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

.footerPartners > .tophalf > .main .newsletter form {
  /* display: flex; */
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: auto;
}

.footerPartners > .tophalf > .main .newsletter form .input {
  border: 1px solid #fff;
  font-family: "Raleway", sans-serif;

  width: 80%;
  padding: 10px;
  background-color: transparent;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  margin: auto;

  /* border-radius: 10px; */
  transition: 0.3s;
}

.footerPartners > .tophalf > .main .newsletter form .input::-webkit-input-placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerPartners > .tophalf > .main .newsletter form .input:-ms-input-placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerPartners > .tophalf > .main .newsletter form .input::placeholder {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerPartners > .tophalf > .main .newsletter form .input:focus {
  outline: none;
  border-bottom: 2px solid #fff;
}

.footerPartners > .tophalf > .main .newsletter form .button {
  border: 2px solid #fff;
  padding: 9px;
  background-color: #fff;
  color: black;
  text-transform: uppercase;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.3s;
}

.footerPartners > .tophalf > .main .newsletter form .button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
}

.footerPartners > .tophalf > .main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.footerPartners > .tophalf > .main .contentSection > h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #008080;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerPartners > .tophalf > .main .contentSection > h1 span {
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
  text-transform: uppercase;
  margin-top: -110px;
  /* margin-bottom: 50px; */
  font-weight: 400;
  color: #d7be69;
  text-shadow: 0 0 0px rgba(0, 0, 0, 0.8);
  text-align: center;
}

.footerPartners > .tophalf > .main > .contentSection h4 {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  text-align: center;
  /* margin-top: 5px; */
  /* text-transform: uppercase; */
  margin-top: 85px;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footerPartners > .tophalf > .main > .icons {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 400px !important;
  margin-right: -20px;
  margin-left: 100px;
}

.footerPartners > .tophalf > .main > .icons > .iconsContainer {
  padding: 10px;
  display: flex;
  margin-left: 40px;
  justify-content: space-evenly;
  width: 300px !important;
  margin-top: 10px;
}

.footerPartners > .tophalf > .main > .icons > h3 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  font-size: 21px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  margin-top: -13px;
}

.footerPartners > .tophalf > .main > .icons > .iconsContainer a .icon {
  font-size: 40px;
  border: 1px solid;
  border-radius: 50%;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
          filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
  text-decoration: none;
  padding: 10px;
  color: #fff;
  margin-left: -20px !important;
  margin-right: -20px !important;
  transition: 0.4s;
  margin-top: -7px;
}

.footerPartners > .tophalf > .main > .icons > .iconsContainer > a .icon:hover {
  color: #d0b49f;
  border: 2px solid #d0b49f;
  cursor: pointer;
}

.footerPartners > .tophalf > .solutionSection {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.footerPartners > .tophalf > .solutionSection h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
}

.footerPartners > .tophalf > .solutionSection h3 > a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

.footerPartners > .tophalf > .solutionSection h3 > a:hover {
  color: #008080;
  cursor: pointer;
}

#bottomNewCont {
  display: flex;
  width: 82%;
  margin: auto;
  justify-content: space-between;
  margin-top: 35px;
}

#leftSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  margin-left: -10px !important;
  text-align: center;
  text-transform: capitalize;
}

#rightSolution {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 15px;
  text-align: center;
  margin-left: -10px !important;
  margin-top: 3px !important;
  text-transform: capitalize;
}

#rightSolution a {
  font-family: "Raleway", sans-serif;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  transition: 0.3s;
}

#rightSolution a:hover {
  color: #008080;
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .footerPartners > .tophalf > .main > .icons {
    margin-left: 0px;
    margin-top: 40px;
  }
  .footerPartners > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 400px;
    margin-top: 10px;
  }

  .footerPartners > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  .footerPartners > .tophalf > .main > .newsletter {
    padding: 40px;
    width: 400px;
    justify-content: center;
    align-items: center;
    margin-bottom: -20px;
    margin-top: -20px;
  }

  .footerPartners > .tophalf > .main .newsletter form .input {
    border: 1px solid #fff;
    font-family: "Raleway", sans-serif;

    width: 60%;
    padding: 10px;
    background-color: transparent;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    margin: auto;
    margin-left: 20px;
    /* border-radius: 10px; */
    transition: 0.3s;
  }

  .footerPartners > .tophalf > .main .contentSection > h1 {
    margin-top: -95px;
  }

  #rightSolution {
    margin-top: -4px !important;
  }

  #leftSolution {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 768px) {
  .footerPartners {
    background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0.1) 80%,
        #f3f3f3
      ),
      url(/static/media/footerBackPartners.25a3d4d1.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    max-height: 400px;
  }

  .footerPartners > .tophalf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .footerPartners > .tophalf > .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .footerPartners > .tophalf > .main > .icons {
    margin-left: -20px;
  }

  .footerPartners > .tophalf > .main > .icons .iconsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerPartners > .tophalf > .main > .icons .iconsContainer > a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    padding: 10px;
    transition: 0.4s;
    margin-right: 15px;
    margin-left: 15px;
  }
  .footerPartners > .tophalf > .main .newsletter form {
    justify-content: center;
    align-items: center;

    margin: auto;
    width: 100%;
  }

  .footerPartners > .tophalf > .main .newsletter form .input {
    width: 50%;
    margin-left: 40px;
    align-self: center;
  }

  .footerPartners > .tophalf > h3 > a {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerPartners > .tophalf > .solutionSection {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  .footerPartners > .tophalf > .main .contentSection > h1 {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerPartners > .tophalf > .main .contentSection > h1 span {
    font-family: "Cormorant Garamond", serif;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 0;
    /* margin-bottom: 50px; */
    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    text-align: center;
  }

  .footerPartners > .tophalf > .main > .contentSection h4 {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    /* text-transform: uppercase; */

    font-weight: 400;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  }

  .footerPartners > .tophalf > .main > .newsletter h3 {
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  }

  .footerPartners > .tophalf > .solutionSection h3 {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-size: 10px;
    text-align: center;
  }

  .footerPartners > .tophalf > .solutionSection h3 > a {
    font-family: "Raleway", sans-serif;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: 0.3s;
  }

  .footerPartners > .tophalf > .main > .icons > .iconsContainer {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 800px;
    margin-top: 10px;
  }

  .footerPartners > .tophalf > .main > .icons > .iconsContainer a .icon {
    font-size: 40px;
    border: 1px solid;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.7));
    text-decoration: none;
    padding: 10px;
    color: #fff;
    margin-left: -20px;
    margin-right: -20px;
    transition: 0.4s;
  }

  #bottomNewCont {
    width: 100% !important;
    margin: auto;
    flex-direction: column;
    margin-top: 0px;
  }

  #leftSolution {
    text-align: center;
    font-size: 10px !important;
    margin-left: 0px !important;
    margin-bottom: 5px;
  }

  #rightSolution {
    text-align: center;
    font-size: 10px !important;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerIsland {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/islandcover.0e96a7c6.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerIsland > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerIsland > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerIsland > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerIsland > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerIsland > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerIsland > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerIsland > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Times New Roman", Times, serif;
  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  /* text-transform: uppercase; */
  font-weight: 400 !important;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > h1 > .special {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #d0b49f;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerIsland {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/islandcover.0e96a7c6.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerIsland > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerIsland > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerIsland > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerIsland > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerIsland > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerIsland > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "Cormorant Garamond", serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerIsland {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/island.9fc16426.jpg);
  }
  .sriLanka_Package > .headerIsland > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerIsland > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerIsland > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerIsland > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerIsland > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerIsland > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Island {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 70vh !important;
}

.superContainer_Island > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Island > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Island > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Island > .mainMobile {
  display: none;
}

.superContainer_Island > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Island {
    height: 100vh !important;
    max-height: 100vh !important;
  }
  .superContainer_Island > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Island {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Island > .navSlider {
    display: none;
  }
  .superContainer_Island > .mainMobile {
    display: flex;
  }

  .superContainer_Island > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerPostcard {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/postcardcover.4cf8341d.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerPostcard > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerPostcard > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerPostcard > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerPostcard > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerPostcard > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerPostcard > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerPostcard > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;
  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerPostcard {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/postcardcover.4cf8341d.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerPostcard > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerPostcard > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerPostcard > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerPostcard > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerPostcard > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerPostcard > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerPostcard {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/postcard.3f51512e.jpg);
  }
  .sriLanka_Package > .headerPostcard > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerPostcard > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerPostcard > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerPostcard > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerPostcard > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerPostcard > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Postcard {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 65vh !important;
}

.superContainer_Postcard > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Postcard > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Postcard > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Postcard > .mainMobile {
  display: none;
}

.superContainer_Postcard > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Postcard {
    height: 100vh !important;
    max-height: 100vh !important;
  }
  .superContainer_Postcard > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Postcard {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Postcard > .navSlider {
    display: none;
  }
  .superContainer_Postcard > .mainMobile {
    display: flex;
  }

  .superContainer_Postcard > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerImmersion {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/immersioncover.a7abc1f6.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerImmersion > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerImmersion > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerImmersion > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerImmersion > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerImmersion > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerImmersion > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerImmersion > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerImmersion {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/immersioncover.a7abc1f6.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerImmersion > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerImmersion > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerImmersion > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerImmersion > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerImmersion > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerImmersion > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerImmersion {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/immersion.e14ddb8e.jpg);
  }
  .sriLanka_Package > .headerImmersion > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerImmersion > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerImmersion > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerImmersion > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerImmersion > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerImmersion > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Immersion {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 80vh !important;
}

.superContainer_Immersion > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Immersion > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Immersion > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Immersion > .mainMobile {
  display: none;
}

.superContainer_Immersion > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Immersion {
    height: 120vh !important;
    max-height: 120vh !important;
  }
  .superContainer_Immersion > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Immersion {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Immersion > .navSlider {
    display: none;
  }
  .superContainer_Immersion > .mainMobile {
    display: flex;
  }

  .superContainer_Immersion > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerGetaway {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/getawaycover.eb5e595d.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerGetaway > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerGetaway > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerGetaway > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerGetaway > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerGetaway > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerGetaway > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerGetaway > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerGetaway {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/getawaycover.eb5e595d.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerGetaway > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerGetaway > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerGetaway > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerGetaway > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerGetaway > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerGetaway > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerGetaway {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/getaway.9174bfc4.jpg);
  }
  .sriLanka_Package > .headerGetaway > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerGetaway > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerGetaway > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerGetaway > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerGetaway > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerGetaway > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Getaway {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 70vh !important;
}

.superContainer_Getaway > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Getaway > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Getaway > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Getaway > .mainMobile {
  display: none;
}

.superContainer_Getaway > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Getaway {
    height: 110vh !important;
    max-height: 110vh !important;
  }
  .superContainer_Getaway > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Getaway {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Getaway > .navSlider {
    display: none;
  }
  .superContainer_Getaway > .mainMobile {
    display: flex;
  }

  .superContainer_Getaway > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerIgnite {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/ignitecover.d275b56f.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerIgnite > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerIgnite > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerIgnite > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerIgnite > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerIgnite > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerIgnite > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerIgnite > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerIgnite {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/ignitecover.d275b56f.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerIgnite > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerIgnite > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerIgnite > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerIgnite > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerIgnite > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerIgnite > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerIgnite {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/ignite.f82148a1.jpg);
  }
  .sriLanka_Package > .headerIgnite > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerIgnite > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerIgnite > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerIgnite > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerIgnite > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerIgnite > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Ignite {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 80vh !important;
}

.superContainer_Ignite > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Ignite > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Ignite > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Ignite > .mainMobile {
  display: none;
}

.superContainer_Ignite > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Ignite {
    height: 110vh !important;
    max-height: 110vh !important;
  }
  .superContainer_Ignite > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Ignite {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Ignite > .navSlider {
    display: none;
  }
  .superContainer_Ignite > .mainMobile {
    display: flex;
  }

  .superContainer_Ignite > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerEssence {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/essencecover.dbab3559.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerEssence > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerEssence > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerEssence > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerEssence > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerEssence > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerEssence > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerEssence > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "century-gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "century-gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerEssence {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/essencecover.dbab3559.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerEssence > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerEssence > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerEssence > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerEssence > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerEssence > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerEssence > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerEssence {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/essence.0235492e.jpg);
  }
  .sriLanka_Package > .headerEssence > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerEssence > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerEssence > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerEssence > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerEssence > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerEssence > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Essence {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 75vh !important;
}

.superContainer_Essence > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Essence > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Essence > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Essence > .mainMobile {
  display: none;
}

.superContainer_Essence > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Essence {
    height: 110vh !important;
    max-height: 110vh !important;
  }
  .superContainer_Essence > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Essence {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Essence > .navSlider {
    display: none;
  }
  .superContainer_Essence > .mainMobile {
    display: flex;
  }

  .superContainer_Essence > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerTravel {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/sample.56f59ebc.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerTravel > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerTravel > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerTravel > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerTravel > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerTravel > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerTravel > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerTravel > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerTravel {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/sample.56f59ebc.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerTravel > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerTravel > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerTravel > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerTravel > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerTravel > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerTravel > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerTravel {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/travelnew.97fce4ad.jpg);
  }
  .sriLanka_Package > .headerTravel > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerTravel > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerTravel > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerTravel > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerTravel > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerTravel > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Travel {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 70vh !important;
}

.superContainer_Travel > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Travel > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Travel > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Travel > .mainMobile {
  display: none;
}

.superContainer_Travel > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Travel {
    height: 100vh !important;
    max-height: 100vh !important;
  }
  .superContainer_Travel > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Travel {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Travel > .navSlider {
    display: none;
  }
  .superContainer_Travel > .mainMobile {
    display: flex;
  }

  .superContainer_Travel > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerRush {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/rushcover.28d25dad.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerRush > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerRush > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerRush > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerRush > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerRush > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerRush > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerRush > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerRush {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/rushcover.28d25dad.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerRush > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerRush > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerRush > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerRush > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerRush > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerRush > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerRush {
    display: flex;
    flex-direction: column;

    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/rush.020409ab.jpg);
  }
  .sriLanka_Package > .headerRush > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerRush > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerRush > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerRush > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerRush > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerRush > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Rush {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 90vh !important;
}

.superContainer_Rush > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Rush > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Rush > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Rush > .mainMobile {
  display: none;
}

.superContainer_Rush > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Rush {
    height: 130vh !important;
    max-height: 130vh !important;
  }
  .superContainer_Rush > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Rush {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Rush > .navSlider {
    display: none;
  }
  .superContainer_Rush > .mainMobile {
    display: flex;
  }

  .superContainer_Rush > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: black;
  height: 80px;
}

.sriLanka_Package > .headerWildlife {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/wildlifecover.e4d28b5b.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerWildlife > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerWildlife > .leftSide > img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerWildlife > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerWildlife > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerWildlife > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerWildlife > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerWildlife > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerWildlife {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/wildlifecover.e4d28b5b.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerWildlife > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerWildlife > .leftSide > img {
    width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerWildlife > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerWildlife > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerWildlife > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerWildlife > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerWildlife {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/wildlife.4feca8a7.jpg);
  }
  .sriLanka_Package > .headerWildlife > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerWildlife > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerWildlife > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerWildlife > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerWildlife > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerWildlife > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Wildlife {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 90vh !important;
}

.superContainer_Wildlife > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Wildlife > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Wildlife > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Wildlife > .mainMobile {
  display: none;
}

.superContainer_Wildlife > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Wildlife {
    height: 130vh !important;
    max-height: 130vh !important;
  }
  .superContainer_Wildlife > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Wildlife {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Wildlife > .navSlider {
    display: none;
  }
  .superContainer_Wildlife > .mainMobile {
    display: flex;
  }

  .superContainer_Wildlife > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #fff;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerBuds {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/budscover.4debe102.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerBuds > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerBuds > .leftSide > img {
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerBuds > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerBuds > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerBuds > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerBuds > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerBuds > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerBuds {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/budscover.4debe102.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerBuds > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerBuds > .leftSide > img {
    width: 280px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerBuds > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerBuds > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerBuds > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerBuds > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerBuds {
    display: flex;
    flex-direction: column;
    padding-top: 140px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/buds.828996aa.jpg);
  }
  .sriLanka_Package > .headerBuds > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerBuds > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerBuds > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerBuds > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerBuds > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerBuds > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Buds {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 80vh !important;
}

.superContainer_Buds > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Buds > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Buds > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Buds > .mainMobile {
  display: none;
}

.superContainer_Buds > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Buds {
    height: 120vh !important;
    max-height: 120vh !important;
  }
  .superContainer_Buds > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Buds {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Buds > .navSlider {
    display: none;
  }
  .superContainer_Buds > .mainMobile {
    display: flex;
  }

  .superContainer_Buds > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerSerenity {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/serenitycover.fbf3533e.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerSerenity > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerSerenity > .leftSide > img {
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerSerenity > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerSerenity > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerSerenity > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerSerenity > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerSerenity > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerSerenity {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/serenitycover.fbf3533e.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerSerenity > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerSerenity > .leftSide > img {
    width: 280px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerSerenity > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerSerenity > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerSerenity > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerSerenity > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerSerenity {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/serenity.ff446fcf.jpg);
  }
  .sriLanka_Package > .headerSerenity > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerSerenity > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerSerenity > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerSerenity > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerSerenity > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerSerenity > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Serenity {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 80vh !important;
}

.superContainer_Serenity > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Serenity > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Serenity > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Serenity > .mainMobile {
  display: none;
}

.superContainer_Serenity > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Serenity {
    height: 120vh !important;
    max-height: 120vh !important;
  }
  .superContainer_Serenity > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Serenity {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    width: auto;
  }

  .superContainer_Serenity > .navSlider {
    display: none;
  }
  .superContainer_Serenity > .mainMobile {
    display: flex;
  }

  .superContainer_Serenity > .main {
    display: none;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerDimension {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/dimensioncover.9163780b.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerDimension > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerDimension > .leftSide > img {
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerDimension > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerDimension > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerDimension > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerDimension > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerDimension > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerDimension {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/dimensioncover.9163780b.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerDimension > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerDimension > .leftSide > img {
    width: 280px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerDimension > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerDimension > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerDimension > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerDimension > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .tourDetails {
    background-color: #f3f3f3;
    padding: 10px;
    display: flex;
    height: 140vh;
    justify-content: center;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerDimension {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/dimension.4ae4c120.jpg);
  }
  .sriLanka_Package > .headerDimension > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerDimension > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerDimension > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerDimension > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerDimension > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerDimension > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.sriLanka_Package {
  background-color: #f3f3f3;
}

.sriLanka_Package > .nav {
  background-color: transparent;
}

.sriLanka_Package > .headerBond {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(/static/media/bondcover.16f6a53a.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
  background-position: center;
}

.sriLanka_Package > .headerBond > .leftSide {
  /* background-color: royalblue; */
  display: flex;
  justify-content: center;
  flex: 0.3 1;
  margin-right: 40px;
  z-index: 4;
}

.sriLanka_Package > .headerBond > .leftSide > img {
  width: 450px;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  margin-left: 150px;
}

.sriLanka_Package > .headerBond > .rightSide {
  display: flex;
  flex: 0.5 1;
  flex-direction: column;
  /* background-color: royalblue; */
}

.sriLanka_Package > .headerBond > .rightSide > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  background-color: gray;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 10px;
  /* margin-top: 200px; */
}

.sriLanka_Package > .headerBond > .rightSide > h1 {
  font-family: "Cormorant Garamond", serif;

  text-transform: uppercase;
  font-size: 50px;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.sriLanka_Package > .headerBond > .rightSide > .button {
  max-width: 200px;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: #008080;
  color: white;
  text-transform: uppercase;
  font-family: "century-gothic", sans-serif;
  font-size: 17px;
  display: none;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  transition: 0.4s;
}

.sriLanka_Package > .headerBond > .rightSide > .button:hover {
  background-color: white;
  color: #008080;
  border: 1px solid #008080;
  cursor: pointer;
}

.tourHeading {
  font-family: "Cormorant Garamond", serif;

  text-align: center;
  font-size: 45px;
  color: #008080;
  background-color: #f3f3f3;
  margin-top: 10px;
  padding-top: 10px;
}

.tourHeading > .special {
  color: #d0b49f;
}

.sriLanka_Package > .tourDetails {
  background-color: #f3f3f3;
  padding: 10px;
  display: flex;

  justify-content: center;
}

.sriLanka_Package > .hotels {
  background-color: #f3f3f3;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
  color: #008080;
  border: 1px solid #008080;
  padding: 10px 20px;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
  cursor: pointer;
  background-color: #008080;
  color: #fff;
}

.sriLanka_Package > .hotels > h1 {
  text-align: center;
  font-family: "Cormorant Garamond", serif;

  font-weight: 400;
  font-size: 50px;
  color: #008080;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sriLanka_Package > .notes {
  padding: 10px;
}

.sriLanka_Package > .notes > h2 {
  font-family: "century-gothic", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  text-transform: uppercase;
  color: #008080;
  margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .notes > p {
  font-family: "century-gothic", sans-serif;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
}

.sriLanka_Package > .form {
  padding: 15px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sriLanka_Package > .form > form {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  width: 50%;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
}

.sriLanka_Package > .form > form > h2 {
  text-align: center;
  font-weight: 400;
  font-family: "Didact Gothic", sans-serif;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
  padding: 10px;
  border: none;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate2 {
  display: flex;
  margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
  font-family: "Didact Gothic", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.sriLanka_Package > .form > form > .seperate > .field {
  padding: 10px;
  border: none;
  flex: 0.35 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
  display: flex;
}

.sriLanka_Package > .form > form > .seperate1 > .field {
  padding: 10px;
  border: none;
  flex: 0.5 1;
  /* margin-left: 5px; */
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus {
  outline: none;
  box-shadow: 0 0 3px #008080;
  background-color: #fff;
}

.sriLanka_Package > .form > form > .button {
  padding: 10px;
  border: none;
  background-color: #008080;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-transform: uppercase;
  font-family: "Didact Gothic", sans-serif;
  border-radius: 5px;
  transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover {
  cursor: pointer;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (width: 1366px) {
  .sriLanka_Package > .headerBond {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/bondcover.16f6a53a.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    background-position: center;
  }

  .sriLanka_Package > .headerBond > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 20px;
    margin-left: 50px;
  }

  .sriLanka_Package > .headerBond > .leftSide > img {
    width: 280px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
  }

  .sriLanka_Package > .headerBond > .rightSide {
    display: flex;
    flex: 0.6 1;
    flex-direction: column;
    /* background-color: royalblue; */
  }

  .sriLanka_Package > .headerBond > .rightSide > h1 {
    font-size: 40px;
  }

  .sriLanka_Package > .headerBond > .rightSide > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .headerBond > .rightSide > .button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 30px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 240px;
  }
}

.sriLanka_Package > .hotels > .special {
  color: #d0b49f;
  font-family: "century-gothic", sans-serif;
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  .sriLanka_Package > .headerBond {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    background-color: #f3f3f3;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url(/static/media/bond.8bccea71.jpg);
  }
  .sriLanka_Package > .headerBond > .leftSide {
    /* max-width: 300px; */
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .sriLanka_Package > .headerBond > .leftSide > img {
    width: 280px;
    position: absolute;
    height: 350px;
    margin: auto;
    /* margin-bottom: 10px; */
    /* margin-left: 30px; */
  }

  .sriLanka_Package > .headerBond > .rightSide {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    justify-content: center;
    align-items: center;
    flex: 0.2 1;
  }

  .sriLanka_Package > .headerBond > .rightSide > h3 {
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
    display: none;
  }

  .sriLanka_Package > .headerBond > .rightSide > h1 {
    font-size: 25px;
    position: absolute;
    margin-top: 0px;
    /* margin-bottom: 10px; */
    text-align: center;
  }

  .sriLanka_Package > .headerBond > .rightSide > .button {
    margin: auto;
    border-radius: 10px;
    padding: 10px 70px;
    font-size: 12px;
  }

  .sriLanka_Package > .tourDetails {
    display: block;
    /* max-height: 50vh !important; */
    /* flex-direction: column; */
  }

  .sriLanka_Package > .hotels > h1 {
    font-size: 28px;
  }

  .sriLanka_Package > .hotels > .level {
    display: flex;
    flex-direction: column;
    border-top: 3px solid #008080;
    border-left: none;
  }

  .sriLanka_Package > .hotels > .level:hover {
    border-left: none;
    border-top: 7px solid #f1c40f;
  }

  .sriLanka_Package > .hotels > .level > .left {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .sriLanka_Package > .hotels > .level > .left > h2 {
    font-size: 22px;
    margin-top: 10px;
  }
  .sriLanka_Package > .hotels > .level > .left > h3 {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
    font-size: 12px;
  }

  .sriLanka_Package > .hotels > .level > .right > .unit > img {
    width: 220px;
    max-width: 220px;
  }

  .sriLanka_Package > .form > form {
    width: 100%;
    max-width: 100%;
  }

  .sriLanka_Package > .form > form > .seperate2 {
    display: flex;
  }

  .sriLanka_Package > .form > form > .seperate {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    flex-direction: column;
  }

  .sriLanka_Package > .form > form > .seperate1 > .field {
    display: flex;
  }
}

.superContainer_Bond {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 10px;
  height: auto;
  max-height: 80vh !important;
}

.superContainer_Bond > .navSlider {
  max-width: 400px;
  padding: 20px;
}

.superContainer_Bond > .navSliderMobile {
  display: none;
}

.box {
  display: flex;
  width: 380px;
  /* height: 20px; */
  /* background-color: darkblue; */
}

.navxx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 355px;
  max-width: 355px;
  background-color: #fff !important;
  border-radius: 10px;
  border-left: 10px solid #008080;
  box-shadow: 0 0 7px hsla(0, 0%, 0%, 0.3);
  margin-bottom: 7px;
  transition: 0.4s;
}

.changer {
  border: 1px solid chocolate;
}

.colorize {
  border-color: red;
}

.navxx:hover {
  cursor: pointer;
  border-left: 10px solid #f1c40f !important;
  box-shadow: 0 0 4px #f1c40f !important;
}

.navxx > .dayNo {
  font-weight: 600;
  color: #008080;
  text-align: center;
  font-size: 15px;
  margin-bottom: 3px;
}

.navxx > .dayTour {
  font-size: 12px;
  text-align: center;
  font-family: "Century-Gothic", sans-serif;
  color: gray;
}

.superContainer_Bond > .main {
  flex: 0.6 1;
  max-width: 800px;
}

.superContainer_Bond > .mainMobile {
  display: none;
}

.superContainer_Bond > .main > .slide {
  max-width: 700px;
}

@media screen and (max-width: 1366px) {
  .superContainer_Bond {
    height: 120vh !important;
    max-height: 120vh !important;
  }
  .superContainer_Bond > .mainMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .superContainer_Bond {
    display: block;
    height: 60vh;
    max-height: 60vh !important;
    max-width: 100vw;
  }

  .superContainer_Bond > .navSlider {
    display: none;
  }
  .superContainer_Bond > .mainMobile {
    display: flex;
  }

  .superContainer_Bond > .main {
    display: none;
  }
}

.sriLanka_Package {
    background-color: #fff;
}

.sriLanka_Package > .nav {
    background-color: transparent;
    position: fixed;
    z-index: 999;
}

.sriLanka_Package > .headtest {
    display: flex;
    padding: 40px;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(/static/media/sample.56f59ebc.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    background-position: center;

}

.sriLanka_Package > .headtest > .leftSide {
    /* background-color: royalblue; */
    display: flex;
    justify-content: center;
    flex: 0.3 1;
    margin-right: 40px;
    z-index: 4;
}

.sriLanka_Package > .headtest > .leftSide > img {
    
    width: 500px;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0,0,0,0.7);
    margin-left: 150px;
}


.sriLanka_Package > .headtest  > .rightSide {
    display: flex;
    flex: 0.5 1;
    flex-direction: column;
    /* background-color: royalblue; */
}

.sriLanka_Package > .headtest  > .rightSide > h3 {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    background-color: gray;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px;
    /* margin-top: 200px; */
}

.sriLanka_Package > .headtest  > .rightSide > h1 {
    font-family: "century-gothic", sans-serif;
    text-transform: uppercase;
    font-size: 60px;
    font-weight: 400;
    margin-top: 10px;
    color:  rgba(255,255,255,0.6);
}

.sriLanka_Package > .headtest  > .rightSide > .button {
    max-width: 200px;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #008080;
    color: white;
    text-transform: uppercase;
    font-family: "century-gothic", sans-serif;
    font-size: 17px;
   
    border-bottom-right-radius: 20px;
    margin-top: 20px;
    transition: 0.4s;
}

.sriLanka_Package > .headtest  > .rightSide > .button:hover {
    background-color: white;
    color: #008080;
    border: 1px solid #008080;
    cursor: pointer;
}

.tourHeading {
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    font-size: 45px;
    color: #008080;
    background-color: #f3f3f3;
    margin-top: 10px;
    padding-top: 10px;
}

.tourHeading > .special {
    color: #d0b49f;
}


.sriLanka_Package > .tourDetails {
    background-color: #f3f3f3;
    padding: 10px;
    display: flex;
    
    justify-content: center;
}

.sriLanka_Package > .hotels {
    background-color: #f3f3f3;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sriLanka_Package > .hotels > .collectionNav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.sriLanka_Package > .hotels > .collectionNav > .linkx {
    color: #008080;
    border: 1px solid #008080;
    padding: 10px 20px;
    font-size: 15px;
    transition: 0.3s;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;

}

.sriLanka_Package > .hotels > .collectionNav > .linkx:hover {
    cursor: pointer;
    background-color: #008080;
    color: #fff;
}

.sriLanka_Package > .hotels > h1 {
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: 400;
    font-size: 50px;
    color: #008080;
    margin-top: 10px;
    margin-bottom: 20px;
}



.sriLanka_Package > .notes {
    padding: 10px;
   
}

.sriLanka_Package > .notes > h2 {
    font-family: "century-gothic", sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    color: #008080;
    margin-bottom: 1px;
}

.sriLanka_Package > .notes > h3 {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    
}

.sriLanka_Package > .notes > p {
    font-family: "century-gothic", sans-serif;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
}

.sriLanka_Package > .form {
    padding: 15px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    
}



.sriLanka_Package > .form > form {
    display: flex;
    flex-direction: column;
    max-width: 60%;
    width: 50%;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 4px rgba(0,0,0,0.4);
}

.sriLanka_Package > .form > form:hover {
    box-shadow: 0 0 2px rgba(0,0,0,0.4);
}

.sriLanka_Package > .form > form > h2 {
    text-align: center;
    font-weight: 400;
    font-family: "century-gothic", sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.sriLanka_Package > .form > form > .field {
    padding: 10px;
    border: none;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .field:focus{
    outline: none;
    box-shadow: 0 0 3px #008080;
    background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate {
    display: flex;
    
}

.sriLanka_Package > .form > form > .seperate2 {
    display: flex;
    margin-bottom: 0px;
}

.sriLanka_Package > .form > form > .seperate2 > label {
    font-family: "century-gothic", sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 1px;
}



.sriLanka_Package > .form > form > .seperate > .field {
    padding: 10px;
    border: none;
    flex: 0.35 1;
    /* margin-left: 5px; */
    margin-right: 5px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate > .field:focus{
    outline: none;
    box-shadow: 0 0 3px #008080;
    background-color: #fff;
}

.sriLanka_Package > .form > form > .seperate1 {
    display: flex;
    
}



.sriLanka_Package > .form > form > .seperate1 > .field {
    padding: 10px;
    border: none;
    flex: 0.5 1;
    /* margin-left: 5px; */
    margin-right: 5px;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .seperate1 > .field:focus{
    outline: none;
    box-shadow: 0 0 3px #008080;
    background-color: #fff;
}


.sriLanka_Package > .form > form > .button {
    padding: 10px;
    border: none;
    background-color: #008080;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    color: #fff;
    text-transform: uppercase;
    font-family: "century-gothic", sans-serif;
    border-radius: 5px;
    transition: 0.4s;
}

.sriLanka_Package > .form > form > .button:hover{
    cursor: pointer;
    box-shadow: none;
    background-color: rgba(0,0,0,0.7);

}


@media screen and (width: 1366px) {

    .sriLanka_Package > .headtest {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        background-image:linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(/static/media/sample.56f59ebc.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        background-position: center;
    
    }
    
    .sriLanka_Package > .headtest > .leftSide {
        /* background-color: royalblue; */
        display: flex;
        justify-content: center;
        flex: 0.3 1;
        margin-right: 20px;
        margin-left: 50px;
    }
    
    .sriLanka_Package > .headtest > .leftSide > img {
        
        width: 300px;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,0.7);
        
    }
    
    
    .sriLanka_Package > .headtest  > .rightSide {
        display: flex;
        flex: 0.6 1;
        flex-direction: column;
        /* background-color: royalblue; */
    }

    
    .sriLanka_Package > .headtest  > .rightSide > h1 {
        font-size: 50px;
    }

    .sriLanka_Package > .headtest  > .rightSide > h3 {
        
        font-size: 12px;
    }

    .sriLanka_Package > .headtest  > .rightSide > .button {
        padding: 10px 20px;
        font-size: 15px;
    }

    .sriLanka_Package > .hotels > .level > .left > h2 {
        font-size: 30px;
    }

    .sriLanka_Package > .hotels > .level > .right > .unit > img {
        width: 240px;
    }
}

.sriLanka_Package > .hotels > .special {
    color: #d0b49f;
    font-family: "century-gothic", sans-serif;
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    .sriLanka_Package > .headtest {
        display: flex;
        flex-direction: column;
        
        background-color: #f3f3f3;
        background-image:linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(/static/media/travel.7326399b.jpg);
    }
    .sriLanka_Package > .headtest > .leftSide {
        max-width: 300px;
        
        
    }

    .sriLanka_Package > .headtest > .leftSide > img {
        width: 280px;
        margin-bottom: 10px;
        margin-left: 30px;
        
    }

    .sriLanka_Package > .headtest > .rightSide {
        width: 100%;
    }

    .sriLanka_Package > .headtest > .rightSide > h3 {
        font-size: 10px;
        margin-top: 20px;
        text-align: center;
        display: none;
    }

    .sriLanka_Package > .headtest > .rightSide > h1 {
        font-size: 25px;
        margin-bottom: 10px;
        text-align: center;
        
    }

    .sriLanka_Package > .headtest > .rightSide > .button {
        margin: auto;
        border-radius: 10px;
        padding: 10px 70px;
        font-size: 12px;
    }

    .sriLanka_Package > .tourDetails {
        display: flex;
        flex-direction: column;
    }

    .sriLanka_Package > .hotels > h1 {
        font-size: 28px;
    }

    .sriLanka_Package > .hotels > .level {
        display: flex;
        flex-direction: column;
        border-top: 3px solid #008080;
        border-left: none;
    }

    .sriLanka_Package > .hotels > .level:hover {
        border-left: none;
        border-top: 7px solid #F1C40F;
    }

    .sriLanka_Package > .hotels > .level > .left {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .sriLanka_Package > .hotels > .level > .left > h2 {
        font-size: 22px;
        margin-top: 10px;
    }
    .sriLanka_Package > .hotels > .level > .left > h3 {
        font-size: 12px;
    }

    .sriLanka_Package > .hotels > .level > .left > h3 > .icon {
        font-size: 12px;
    }

    .sriLanka_Package > .hotels > .level > .right > .unit > img {
        width: 220px;
        max-width: 220px;
    }

    .sriLanka_Package > .form > form {
        width: 100%;
        max-width: 100%;
    }

    .sriLanka_Package > .form > form > .seperate2 {
        display: flex;
        
    }

    .sriLanka_Package > .form > form > .seperate {
        display: flex;
        flex-direction: column;
    }

    .sriLanka_Package > .form > form > .seperate1 {
        display: flex;
        flex-direction: column;
        
    }
    
    .sriLanka_Package > .form > form > .seperate1 > .field {
        display: flex;
        
    }
}

