@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

.hotels {
  margin: auto;
  width: 100%;
}

.hotels > .Heading {
  font-family: "Didact Gothic", sans-serif;
  font-weight: 400;
  /* font-style: italic; */
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 24px;
  text-align: left;
  margin-top: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 10px;
}

.hotels > .HotelsContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.hotels > .HotelsContainer > .row {
  display: flex;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .hotels > .HotelsContainer {
    display: flex;
  }
  .hotels > .Heading {
    font-family: "Didact Gothic", sans-serif;
    font-weight: 400;
    /* font-style: italic; */
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: left;
    margin-top: 30px;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
  }

  .hotels > .HotelsContainer > .row {
    display: flex;
    flex-direction: column;
  }
}
